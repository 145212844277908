import styled from 'styled-components';
import { font } from '../../style-utils/text';

export const WarningMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`;

export const PaymentPlansContainer = styled.div<{
  bg?: string;
  borderColor?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid ${({ borderColor }) => `${borderColor}`};
  border-radius: 8px;
  flex-basis: 200px;
  max-width: 100%;
  overflow: auto;
  background: ${({ bg }) => (bg ? `${bg}` : 'white')};
  cursor: pointer;
`;

export const TextContainer = styled.div<{ color?: string }>`
  color: ${({ color }) => color && color};
  overflow: auto;
  max-height: 160px;
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const PaymentPlansText = styled.p<{
  paddingLeft?: number;
  fontSize?: number;
}>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px;` : '12px')};
  font-family: ${font.family};
  padding: 8px;
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}px;`}
  margin: 0;
`;

export const PaymentPlansTextContainer = styled.div<{ paddingBottom?: number }>`
  display: flex;
  flex: 1;
  ${({ paddingBottom }) => paddingBottom && `margin-bottom: ${paddingBottom};`}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
`;

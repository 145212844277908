import React, { useEffect, useState } from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { Text, font } from '../../../style-utils/text';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { useNavigate } from 'react-router-dom';
import {
  CreateVacancyInput,
  DeleteButton,
  ErrorLabel,
  SkillButton,
  SkillContainer,
  StyledJobInput,
  StyledSelectMenu,
  SubmitButtonsContainer,
} from './jobs.styled';
import {
  JobOfferData,
  NewJobOfferData,
} from '../../../interfaces/job-interfaces';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { ButtonContainer, ButtonTitle } from '../../login/login.styled';
import { colors } from '../../../style-utils/colors';
import { SelectDate } from '../applicants/candidates/date-picker';
import { TextEditor } from '../../styled-quill/text-editor';
import { sanitizeInput } from '../../../function-utils/sanitize-input';

const jobOfferInitialState: NewJobOfferData = {
  general_company_info: '',
  job_position: '',
  job_description: '',
  job_category: '',
  specialty: '',
  skills: [],
  seniority_level: '',
  industry: '',
  job_duration: '',
  responsibilities: '',
  work_location: '',
  location: '',
  benefits: '',
  status: '',
  job_aplications: 0,
  company_id: '',
  expiration_date: '',
};

const predefinedSkills = [
  'JavaScript',
  'Java',
  'PHP',
  'Python',
  'NodeJS',
  'React',
  'Angular',
  'Vue',
  'SQL',
  'PostgreSQL',
  'MongoDB',
  'Firebase',
  'NextJS',
];

interface EditableJobOfferProps {
  // editableJobOfferData: JobOfferData | undefined;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  cookies?: any;
  // eslint-disable-next-line
  editableJobOfferData: JobOfferData | undefined;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const EditJobOffer: React.FC<EditableJobOfferProps> = ({
  company,
  editableJobOfferData,
  setUser,
}) => {
  const [updatedJobOfferData, setUpdatedJobOfferData] =
    useState<NewJobOfferData>(
      editableJobOfferData ? editableJobOfferData : jobOfferInitialState
    );
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const [customSkillInput, setCustomSkillInput] = useState<string>('');
  const [expirationDateValue, setExpirationDateValue] = useState<
    Date | undefined
  >(new Date(updatedJobOfferData.expiration_date));
  const [wrongExpirationDate, setWrongExpirationDate] = useState<string>('');

  const navigate = useNavigate();
  const height = useWindowHeight();
  const { name } = company;

  const updateJobOffer = (field: keyof NewJobOfferData, value: string) => {
    setUpdatedJobOfferData({ ...updatedJobOfferData, [field]: value });
  };

  useEffect(() => {
    if (!editableJobOfferData) {
      navigate(`/${name}/hiring/job-offers`);
    }
  }, [editableJobOfferData, name, navigate]);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    setUpdatedJobOfferData({ ...updatedJobOfferData, [name]: value });
  };
  // eslint-disable-next-line
  const onSubmit = async (e: any, versionType: string) => {
    e.preventDefault();

    if (versionType === 'draft') {
      updatedJobOfferData.status = 'draft';
    } else if (versionType === 'publish') {
      updatedJobOfferData.status = 'active';
    }

    try {
      let response;
      if (versionType === 'draft') {
        response = await axios.put(
          `${apiRoute}/${name}/hiring/edit-job-offer`,
          updatedJobOfferData
        );

        if (response.status === 200) {
          console.log('Job successfully edited:', response.data);
          navigate && navigate(`/${name}/jobs/job-offers`);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } else if (versionType === 'publish') {
        if (!updatedJobOfferData.expiration_date) {
          setWrongExpirationDate('Please enter a valid expiration date');

          return;
        } else {
          setWrongExpirationDate('');
        }
        const invalidFields = validateNewJobOfferData(updatedJobOfferData);
        setInvalidFieldsInputArray(invalidFields);

        console.log(invalidFields);

        if (wrongExpirationDate) return;

        if (invalidFields.length === 0) {
          response = await axios.put(
            `${apiRoute}/${name}/hiring/edit-job-offer`,
            updatedJobOfferData
          );
          if (response.status === 200) {
            console.log('Job successfully edited:', response.data);
            navigate && navigate(`/${name}/jobs/job-offers`);
          } else {
            console.error('Unexpected response status:', response.status);
          }
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios error:', axiosError.message);
        if (axiosError.response) {
          console.error('Response error:', axiosError.response.data);
        } else if (axiosError.request) {
          console.error('Request error:', axiosError.request);
        }
      } else {
        console.error('Non-Axios error:', error);
      }
    }
  };

  const handleSkillSelect = (skill: string) => {
    // Check if the skill is not already selected
    if (!updatedJobOfferData.skills.includes(skill)) {
      setUpdatedJobOfferData({
        ...updatedJobOfferData,
        skills: [...updatedJobOfferData.skills, skill],
      });
    }
  };

  const handleCustomSkillAdd = (customSkill: string) => {
    // Check if the custom skill is not already in predefinedSkills
    if (!predefinedSkills.includes(customSkill)) {
      setUpdatedJobOfferData({
        ...updatedJobOfferData,
        skills: [...updatedJobOfferData.skills, customSkill],
      });
    }
  };

  const handleSkillRemove = (skill: string) => {
    setUpdatedJobOfferData({
      ...updatedJobOfferData,
      skills: updatedJobOfferData.skills.filter((s) => s !== skill),
    });
  };

  const handleCustomSkillChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomSkillInput(e.target.value);
  };

  const handleCustomSkillKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && customSkillInput.trim() !== '') {
      handleCustomSkillAdd(customSkillInput.trim());

      setUpdatedJobOfferData({
        ...updatedJobOfferData,
        skills: [...updatedJobOfferData.skills, customSkillInput.trim()],
      });
      setCustomSkillInput('');
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewJobOfferData = (data: any) => {
    const requiredFields = Object.keys(updatedJobOfferData);

    const emptyFields: string[] = [];

    requiredFields.forEach((field) => {
      if (data[field].length === 0) {
        emptyFields.push(field);
      }
    });

    return emptyFields;
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setExpirationDateValue(date);
      const selectedDate = new Date(date);
      const today = new Date();
      selectedDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      if (selectedDate < today) {
        setWrongExpirationDate('Expiration date cannot be before today');

        return;
      } else if (selectedDate.getFullYear() > today.getFullYear() + 3) {
        setWrongExpirationDate('Expiration date cannot be more than 3 years');

        return;
      } else {
        setWrongExpirationDate('');
      }
      setUpdatedJobOfferData({
        ...updatedJobOfferData,
        expiration_date: selectedDate.toString(),
      });
    }
  };

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={company} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={company}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer bgColor={colors.creme_100}>
              <div>
                <Text
                  fontSize={30}
                  mb={0}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Edit Job Vacancy
                </Text>
                <Text fontSize={14} mt={6} fontFamily={font.family}>
                  Edit your selected job post here!
                </Text>
              </div>
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  General Company Information
                </Text>
                <StyledJobInput
                  type="text"
                  name="general_company_info"
                  value={sanitizeInput(
                    updatedJobOfferData.general_company_info
                  )}
                  placeholder={'Enter company info'}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('general_company_info') && (
                <ErrorLabel>* Please enter general company info</ErrorLabel>
              )}
              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Job position
                </Text>
                <StyledJobInput
                  type="text"
                  name="job_position"
                  value={updatedJobOfferData.job_position}
                  placeholder={'Enter job position'}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('job_position') && (
                <ErrorLabel>* Please enter job position</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Job Description
                </Text>
                <TextEditor
                  height="180px"
                  minHeight="180px"
                  backgroundColor={colors.neutral_50}
                  placeholder="Job Description"
                  changeHandler={(value) => {
                    updateJobOffer('job_description', value);
                  }}
                  defaultValue={updatedJobOfferData.job_description}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('job_description') && (
                <ErrorLabel>* Please enter job description</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Job Category
                </Text>
                <StyledSelectMenu
                  name="job_category"
                  value={updatedJobOfferData.job_category}
                  onChange={handleInputChange}
                  width="99%"
                >
                  <option value={'Full Time'}>Full Time</option>
                  <option value={'Part Time'}>Part Time</option>
                  <option value={'Contractor Based'}>Contractor Based</option>
                </StyledSelectMenu>
              </CreateVacancyInput>

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Specialty
                </Text>
                <StyledJobInput
                  type="text"
                  name="specialty"
                  value={updatedJobOfferData.specialty}
                  placeholder={'Enter job position'}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('specialty') && (
                <ErrorLabel>* Please enter specialty</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Skills
                </Text>
                <div>
                  <StyledSelectMenu
                    name="skills"
                    value=""
                    onChange={(e) => handleSkillSelect(e.target.value)}
                    width="99%"
                  >
                    <option value="" disabled>
                      Select or Add Skills
                    </option>
                    {predefinedSkills.map((skill) => (
                      <option key={skill} value={skill}>
                        {skill}
                      </option>
                    ))}
                  </StyledSelectMenu>

                  <StyledJobInput
                    customSkill
                    type="text"
                    value={customSkillInput}
                    placeholder="Add a custom skill"
                    onChange={handleCustomSkillChange}
                    onKeyDown={handleCustomSkillKeyDown}
                  />
                </div>
                <SkillContainer>
                  {updatedJobOfferData.skills.map((skill) => (
                    <SkillButton key={skill}>
                      {skill}
                      <DeleteButton onClick={() => handleSkillRemove(skill)}>
                        &#10006;
                      </DeleteButton>
                    </SkillButton>
                  ))}
                </SkillContainer>
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('skills') && (
                <ErrorLabel>* Please enter at least one skill</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Seniority Level
                </Text>
                <StyledSelectMenu
                  name="seniority_level"
                  value={updatedJobOfferData.seniority_level}
                  onChange={handleInputChange}
                  width="99%"
                >
                  <option value={''} disabled>
                    Select a Seniority Level
                  </option>
                  <option value={'Internship'}>Internship</option>
                  <option value={'Junior'}>Junior</option>
                  <option value={'Intermediate'}>Intermediate</option>
                  <option value={'Senior'}>Senior</option>
                  <option value={'Lead'}>Lead</option>
                </StyledSelectMenu>
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('seniority_level') && (
                <ErrorLabel>* Please select seniority level</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Industry
                </Text>
                <StyledJobInput
                  type="text"
                  name="industry"
                  value={updatedJobOfferData.industry}
                  placeholder={'Enter industry'}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('industry') && (
                <ErrorLabel>* Please enter industry</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Job Duration
                </Text>
                <StyledJobInput
                  type="text"
                  name="job_duration"
                  value={updatedJobOfferData.job_duration}
                  placeholder={'Enter job duration'}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('job_duration') && (
                <ErrorLabel>* Please enter job duration</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Responsibilities
                </Text>
                <TextEditor
                  height="180px"
                  minHeight="180px"
                  backgroundColor={colors.neutral_50}
                  placeholder="Enter job responsibilities"
                  changeHandler={(value) => {
                    updateJobOffer('responsibilities', value);
                  }}
                  defaultValue={updatedJobOfferData.responsibilities}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('responsibilities') && (
                <ErrorLabel>* Please enter job responsibilities</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Work Location
                </Text>
                <StyledSelectMenu
                  name="work_location"
                  value={updatedJobOfferData.work_location}
                  onChange={handleInputChange}
                  width="99%"
                >
                  <option value={''} disabled>
                    Select a work location
                  </option>
                  <option value={'Office'}>Office</option>
                  <option value={'Remote'}>Remote</option>
                  <option value={'Hybrid'}>Hybrid</option>
                </StyledSelectMenu>
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('work_location') && (
                <ErrorLabel>* Please select work location</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Location (city, state, country...)
                </Text>
                <StyledJobInput
                  type="text"
                  name="location"
                  value={updatedJobOfferData.location}
                  placeholder={'Enter location'}
                  onChange={handleInputChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('location') && (
                <ErrorLabel>* Please enter job location</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Benefits
                </Text>

                <TextEditor
                  height="180px"
                  minHeight="180px"
                  backgroundColor={colors.neutral_50}
                  placeholder="Enter benefits"
                  changeHandler={(value) => {
                    updateJobOffer('benefits', value);
                  }}
                  defaultValue={updatedJobOfferData.benefits}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('benefits') && (
                <ErrorLabel>* Please enter job benefits</ErrorLabel>
              )}

              <CreateVacancyInput>
                <Text
                  mt={0}
                  mb={8}
                  fontSize={18}
                  fontWeight={700}
                  fontFamily={font.family}
                >
                  Expiration date
                </Text>
                <SelectDate
                  value={expirationDateValue}
                  onChange={handleDateChange}
                />
              </CreateVacancyInput>
              {invalidFieldsInputArray.includes('expiration_date') && (
                <ErrorLabel>* Please enter expiration date</ErrorLabel>
              )}
              {wrongExpirationDate && (
                <ErrorLabel>{wrongExpirationDate}</ErrorLabel>
              )}

              <SubmitButtonsContainer>
                <ButtonContainer
                  backgroundColor={colors.primary_100}
                  type="button"
                  onClick={(e) => onSubmit(e, 'draft')}
                  mt={32}
                >
                  <ButtonTitle fontColor={'black'}>Save as Draft</ButtonTitle>
                </ButtonContainer>
                <ButtonContainer
                  backgroundColor={colors.primary_500}
                  type="button"
                  onClick={(e) => onSubmit(e, 'publish')}
                  mt={32}
                >
                  <ButtonTitle>Publish</ButtonTitle>
                </ButtonContainer>
              </SubmitButtonsContainer>
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};

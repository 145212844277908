import React, { useEffect, useRef, useState } from 'react';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
// import { Text, font } from '../../../style-utils/text';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Text, font } from '../../../style-utils/text';
// import { InfoCard } from '../../info-card/info-card';
import {
  ActiveJobOffersWrapper,
  ButtonIconDiv,
  CircleWrapper,
  DashboardInfoCardWrapper,
  DueDateDiv,
  JobOffersContainerHeader,
  NoteDiv,
  NotificationContainer,
  NotificationDescriptionDiv,
  PieChartWrapper,
  StyledGenderBalanceAndAvgEmployeeChartWrapper,
  StyledHomeChartWrapper,
  ToDoDescriptionDiv,
} from './home.styled';
import DynamicCircle from '../../pie-chart/pie-chart';
import { ButtonContainer, ButtonTitle } from '../../login/login.styled';
import {
  ActiveJobOffers,
  DeleteContainer,
  IconsDiv,
  JobNameDiv,
  JobOffer,
  JobOffersContainer,
  JobOffersDetailsContainer,
  JobOffersParent,
  OfferCount,
  WrapperDiv,
} from '../jobs/jobs.styled';
import { colors } from '../../../style-utils/colors';
import { useSelectedJob } from '../../../context/selected-job-context';
import {
  JobApplicationData,
  JobOfferData,
} from '../../../interfaces/job-interfaces';
import { createItem, fetchAllItems } from '../../requests/requests';
import { offerColors } from '../jobs/job-offers';
import {
  InfoCardWrapper,
  InfoIconWrapper,
  TextWrapper,
} from '../../../components/info-card/info-card.styled';
import { InfoIcon } from '../../icons/info-icon';
import { DeleteIcon } from '../../icons/delete-icon';
import { SquareIcon } from '../../icons/square-icon';
import { extractDateAndTime } from '../../../function-utils/date-time-extractor';
import { CloseIcon } from '../../icons/close-icon';
import {
  StyledSpan,
  JobStyledButton,
  CloseIconWrapper,
} from '../job-board/job-board.styled';
import { normalizeCompanyName } from '../../../function-utils/slug-normalizer';
import { DashboardIcon } from '../../icons/dashboard';
import { Chat } from '../../chat/chat';
import { GenderBalance } from '../../gender-balace/gender-balance';
import { AppTutorialModal } from '../../app-tutorial/app-tutorial-modal';
import ReportingBarChart from '../reporting/components/charts/bar-chart/bar-chart';
import { useHomeChartsData } from './use-home-charts-data';
import ReportingMultiLineChart from '../reporting/components/charts/multi-line-chart/multi-line-chart';
import { NavigateFunction } from 'react-router-dom';

const lastNotificationsData = [
  {
    notification: 'Marko Stefanovski has requested time off.',
    date: 'Fri Feb 16 2024',
  },
  {
    notification: 'Kostadin Stamboliev updated his personal info.',
    date: 'Fri Feb 16 2024',
  },
  {
    notification: 'Olga Penovska submitted attendance.',
    date: 'Fri Feb 16 2024',
  },
];

const notificationsData = [
  {
    notification: 'Marko Stefanovski updated his personal info.',
    date: 'Thu Feb 15 2024',
  },
  {
    notification: 'Sara Miftar submitted attendance.',
    date: 'Thu Feb 15 2024',
  },
];

const toDoData = [
  {
    toDoDescription: "Complete Olga Penovska's CV",
    date: '28.02.2024',
    note: 'Add new technologies',
  },
  {
    toDoDescription: "Complete Kostadin Stamboliev's CV",
    date: '06.03.2024',
    note: 'Change previous experience',
  },
];

interface MyCompanyProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const MyCompany: React.FC<MyCompanyProps> = ({
  navigate,
  user,
  setUser,
}) => {
  const height = useWindowHeight();
  const [jobOffersData, setJobOffersData] = useState<JobOfferData[]>([]);
  const [toggleJobDetail, setToggleJobDetail] = useState<string | null>(null);
  const [applicantsLastWeek, setApplicantsLastWeek] = useState<
    JobApplicationData[]
  >([]);
  const [interviewedApplicantsLastWeek, setInterviewedApplicantsLastWeek] =
    useState<JobApplicationData[]>([]);
  const [hiredApplicantsLastWeek, setHiredApplicantsLastWeek] = useState<
    JobApplicationData[]
  >([]);
  const [hotProspectApplicants, setHotProspectApplicants] = useState<
    JobApplicationData[]
  >([]);
  const [
    todayScheduledInterviewApplicants,
    setTodayScheduledInterviewApplicants,
  ] = useState<JobApplicationData[]>([]);
  const [selectedJobOffer, setSelectedJobOffer] = useState<JobOfferData>();
  const [parentHeight, setParentHeight] = useState('');
  const parentRef = useRef<HTMLDivElement>(null);
  const { barChartData, multiLineChartData } = useHomeChartsData();

  setTodayScheduledInterviewApplicants;

  const { name, id } = user;

  const {
    setJob,
  }: {
    setJob: (job: JobOfferData | null) => void;
  } = useSelectedJob();

  let offersColorCounter = 0;
  let activeJobsCount = 0;

  const getJobOffersFromDB = async () => {
    let response;
    if (id) {
      response = await createItem(`${name}/hiring/job-offers`, user);
    }

    setJobOffersData(response as JobOfferData[]);
  };

  const getApplicantsDataForCharts = async () => {
    let response;

    if (id) {
      response = await fetchAllItems(`${name}/applicants/charts/${id}`);
    }

    setApplicantsLastWeek(response as []);

    if (response) {
      console.log('Ova·e·responsot:', response);
      const {
        applicantsLastWeek,
        interviewedApplicantsLastWeek,
        hiredApplicantsLastWeek,
        hotProspectApplicants,
        todayScheduledInterviewApplicants,
      }: // eslint-disable-next-line
      any = response;

      setApplicantsLastWeek(applicantsLastWeek as []);
      setInterviewedApplicantsLastWeek(interviewedApplicantsLastWeek as []);
      setHiredApplicantsLastWeek(hiredApplicantsLastWeek as []);
      setHotProspectApplicants(hotProspectApplicants as []);
      setTodayScheduledInterviewApplicants(
        todayScheduledInterviewApplicants as []
      );
    }
  };

  useEffect(() => {
    getJobOffersFromDB();
  }, [user]);

  useEffect(() => {
    getApplicantsDataForCharts();
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      const currentParentHeight = parentRef.current
        .clientHeight as unknown as string;
      setParentHeight(currentParentHeight);
    }
  }, [toggleJobDetail]);

  const selectJobToViewCandidates = (job: JobOfferData) => {
    setJob(job);
    navigate && navigate(`/${name}/applicants`);
  };

  const handleToggleDetails = (job: JobOfferData) => {
    setToggleJobDetail((previousToggled) =>
      previousToggled === job.id ? null : job.id
    );

    setSelectedJobOffer(job);

    if (parentRef.current) {
      parentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const displayDate = (dateString: string) => {
    const { formattedDate } = extractDateAndTime(dateString);

    return formattedDate;
  };

  const handleCloseClick = () => {
    setToggleJobDetail(null);
  };

  return (
    <LayoutContainer height={height}>
      <TopMenu
        navigationUrl={'/admin'}
        user={user}
        setUser={setUser}
        isSuperAdmin={user.account_type === 'superAdmin'}
      />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={user}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer bgColor={colors.creme_100}>
              <Text
                fontWeight={700}
                fontSize={38}
                mt={0}
                pt={0}
                mb={0}
                pb={0}
                fontFamily={font.family}
              >
                {' '}
                Hiring Dashboard
              </Text>
              <Text mb={32} fontFamily={font.family}>
                Here, you can effortlessly create, customize, and manage job
                positions, streamline postings, and track applications.
              </Text>
              <DashboardInfoCardWrapper>
                <InfoCardWrapper
                  onClick={() => {
                    if (hotProspectApplicants.length > 0) {
                      if (navigate) {
                        navigate(`/${name}/hot-prospects`);
                      }
                      setJob(null);
                    }
                  }}
                >
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                  <TextWrapper>
                    <Text fontFamily={font.family}>
                      {hotProspectApplicants.length === 0
                        ? 'No applicants to review today'
                        : `There ${
                            hotProspectApplicants.length === 1 ? 'is' : 'are'
                          } ${hotProspectApplicants.length} hot ${
                            hotProspectApplicants.length === 1
                              ? 'candidate'
                              : 'candidates'
                          } waiting for review.`}
                    </Text>
                  </TextWrapper>
                </InfoCardWrapper>

                <InfoCardWrapper
                  onClick={() => {
                    if (todayScheduledInterviewApplicants.length > 0) {
                      if (navigate) {
                        navigate(`/${name}/interviews-today`);
                      }
                      setJob(null);
                    }
                  }}
                >
                  <InfoIconWrapper>
                    <InfoIcon />
                  </InfoIconWrapper>
                  <TextWrapper>
                    <Text fontFamily={font.family}>
                      {todayScheduledInterviewApplicants.length === 0
                        ? 'No interviews scheduled today'
                        : `You have ${
                            todayScheduledInterviewApplicants.length
                          } ${
                            todayScheduledInterviewApplicants.length === 1
                              ? 'interview'
                              : 'interviews'
                          } today.`}
                    </Text>
                  </TextWrapper>
                </InfoCardWrapper>
              </DashboardInfoCardWrapper>

              {/* Dynamic Circles container */}
              <PieChartWrapper>
                <CircleWrapper>
                  <DynamicCircle
                    number={192}
                    string={'Views'}
                    light={12}
                    dark={72}
                  />
                </CircleWrapper>
                <CircleWrapper>
                  <DynamicCircle
                    number={applicantsLastWeek.length}
                    string={'Applicants'}
                    light={69}
                    dark={32}
                  />
                </CircleWrapper>
                <CircleWrapper>
                  <DynamicCircle
                    number={interviewedApplicantsLastWeek.length}
                    string={'Interviewed'}
                    light={12}
                    dark={17}
                  />
                </CircleWrapper>
                <CircleWrapper>
                  <DynamicCircle
                    number={hiredApplicantsLastWeek.length}
                    string={'Hired'}
                    light={112}
                    dark={32}
                  />
                </CircleWrapper>
              </PieChartWrapper>

              <StyledGenderBalanceAndAvgEmployeeChartWrapper>
                {/* Gender Balance component */}
                <GenderBalance />
                <StyledHomeChartWrapper>
                  <ReportingBarChart
                    name="Average employee"
                    barChartData={barChartData}
                  />
                </StyledHomeChartWrapper>
              </StyledGenderBalanceAndAvgEmployeeChartWrapper>

              <StyledGenderBalanceAndAvgEmployeeChartWrapper>
                <StyledHomeChartWrapper>
                  <ReportingMultiLineChart
                    name="Salary"
                    multiLineChartData={multiLineChartData}
                  />
                </StyledHomeChartWrapper>
              </StyledGenderBalanceAndAvgEmployeeChartWrapper>

              {/* Active Job Offers */}
              <ActiveJobOffersWrapper>
                <ActiveJobOffers isDashboard>
                  <JobOffersContainerHeader>
                    <Text
                      mt={1}
                      mb={1}
                      fontSize={22}
                      fontWeight={700}
                      fontFamily={font.family}
                    >
                      Active Job Offers
                    </Text>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ButtonContainer
                        backgroundColor={colors.blue}
                        type="button"
                        mr={20}
                      >
                        <a
                          href={`/${normalizeCompanyName(name)}`}
                          style={{ textDecoration: 'none' }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ButtonTitle fontColor="white">
                            <DashboardIcon />
                            Career Dashboard
                          </ButtonTitle>
                        </a>
                      </ButtonContainer>
                      <ButtonContainer
                        backgroundColor={colors.primary_500}
                        type="button"
                        onClick={() =>
                          navigate && navigate(`/${name}/jobs/create-job-offer`)
                        }
                        mt={1}
                        mb={1}
                      >
                        <ButtonTitle>+ Create New Job Offer</ButtonTitle>
                      </ButtonContainer>
                    </div>
                  </JobOffersContainerHeader>
                  <JobOffersParent ref={parentRef}>
                    <JobOffersContainer
                      width={toggleJobDetail ? '60%' : '100%'}
                    >
                      {jobOffersData.map((jobOffer, index) => {
                        // const colorIndex =
                        //   index < offerColors.length
                        //     ? index
                        //     : Math.floor(Math.random() * offerColors.length);

                        if (jobOffer.status === 'active') {
                          offersColorCounter += 1;
                          activeJobsCount += 1;

                          if (offersColorCounter > offerColors.length) {
                            offersColorCounter = 1;
                          }

                          return (
                            <React.Fragment key={index}>
                              <JobOffer
                                // w={toggleJobDetail ? '50%' : '95%'}
                                // h={toggleJobDetail ? '88px' : '70px'}
                                onClick={() => handleToggleDetails(jobOffer)}
                              >
                                <JobNameDiv>
                                  <OfferCount
                                    backgroundColor={
                                      offerColors[offersColorCounter - 1]
                                    }
                                  >
                                    <Text
                                      mt={0}
                                      mb={0}
                                      fontSize={12}
                                      fontWeight={400}
                                      fontFamily={font.family}
                                    >
                                      {/* Offer {index + 1} */}
                                      Offer {activeJobsCount}
                                    </Text>
                                  </OfferCount>

                                  <Text
                                    mt={0}
                                    mb={0}
                                    fontSize={18}
                                    fontWeight={400}
                                    fontFamily={font.family}
                                  >
                                    {jobOffer.job_position}
                                  </Text>
                                </JobNameDiv>

                                <IconsDiv>
                                  <ButtonContainer
                                    borderRadius="24"
                                    backgroundColor={colors.primary_500}
                                    type="button"
                                    onClick={(
                                      e: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                      e.stopPropagation();
                                      selectJobToViewCandidates(jobOffer);
                                    }}
                                    mt={1}
                                  >
                                    <ButtonTitle>View Candidates</ButtonTitle>
                                  </ButtonContainer>
                                </IconsDiv>
                              </JobOffer>
                            </React.Fragment>
                          );
                        }
                      })}
                    </JobOffersContainer>
                    {selectedJobOffer &&
                      toggleJobDetail === selectedJobOffer.id && (
                        <JobOffersDetailsContainer height={`${parentHeight}px`}>
                          <Text
                            fontSize={36}
                            fontWeight={700}
                            fontFamily={font.family}
                            mt={0}
                            mb={8}
                          >
                            {selectedJobOffer.job_position}
                          </Text>
                          <Text
                            fontSize={14}
                            fontFamily={font.family}
                            color={colors.creme_500}
                          >
                            {displayDate(selectedJobOffer.createdAt)}
                          </Text>
                          {user && (
                            <>
                              <Text
                                fontSize={16}
                                lineHeight="19.2"
                                fontFamily={font.family}
                              >
                                <StyledSpan>Company:&nbsp;</StyledSpan>
                                {user.name}
                              </Text>
                              <Text
                                fontSize={16}
                                lineHeight="19.2"
                                fontFamily={font.family}
                              >
                                <StyledSpan>Web:&nbsp;</StyledSpan>
                                {user.website}
                              </Text>
                              <Text
                                fontSize={16}
                                lineHeight="19.2"
                                fontFamily={font.family}
                              >
                                <StyledSpan>Address:&nbsp;</StyledSpan>
                                {user.address}
                              </Text>
                              <Text
                                fontSize={16}
                                lineHeight="19.2"
                                fontFamily={font.family}
                              >
                                <StyledSpan>Email:&nbsp;</StyledSpan>
                                {user.email}
                              </Text>
                              <Text
                                fontSize={16}
                                lineHeight="19.2"
                                fontFamily={font.family}
                              >
                                <StyledSpan>Phone:&nbsp;</StyledSpan>
                                {user.phone_number}
                              </Text>
                            </>
                          )}
                          <WrapperDiv gap="8px" mb="25px" mt="25px" flexWrap>
                            <JobStyledButton
                              bgColor={colors.secondary_500}
                              color={colors.white}
                              borderRadius="32px"
                              padding="8px 16px"
                            >
                              {selectedJobOffer.job_category}
                            </JobStyledButton>
                            <JobStyledButton
                              bgColor={colors.secondary_500}
                              color={colors.white}
                              borderRadius="32px"
                              padding="8px 16px"
                            >
                              {selectedJobOffer.work_location}
                            </JobStyledButton>
                            <JobStyledButton
                              bgColor={colors.secondary_500}
                              color={colors.white}
                              borderRadius="32px"
                              padding="8px 16px"
                            >
                              {selectedJobOffer.seniority_level}
                            </JobStyledButton>
                            <JobStyledButton
                              bgColor={colors.secondary_500}
                              color={colors.white}
                              borderRadius="32px"
                              padding="8px 16px"
                            >
                              {selectedJobOffer.job_duration}
                            </JobStyledButton>
                          </WrapperDiv>
                          <Text
                            fontSize={18}
                            fontWeight={700}
                            mt={5}
                            mb={8}
                            fontFamily={font.family}
                            color={colors.black}
                            lineHeight="21.6px"
                          >
                            Job Description:
                          </Text>
                          <Text
                            display="block"
                            mt={5}
                            mb={5}
                            fontFamily={font.family}
                            dangerouslySetInnerHTML={{
                              __html: selectedJobOffer.job_description,
                            }}
                          />
                          <Text
                            fontFamily={font.family}
                            fontSize={18}
                            fontWeight={700}
                            lineHeight="21.6px"
                          >
                            Skill Required:
                          </Text>
                          {selectedJobOffer.skills.map((_e, i) => {
                            return (
                              <Text
                                display="block"
                                key={i}
                                mt={5}
                                mb={5}
                                fontFamily={font.family}
                                dangerouslySetInnerHTML={{
                                  __html: selectedJobOffer.skills[i],
                                }}
                              />
                            );
                          })}
                          <Text
                            fontFamily={font.family}
                            fontSize={18}
                            fontWeight={700}
                          >
                            Key Responsibilities
                          </Text>
                          <Text
                            display="block"
                            mt={5}
                            mb={5}
                            fontFamily={font.family}
                            dangerouslySetInnerHTML={{
                              __html: selectedJobOffer.responsibilities,
                            }}
                          />
                          <Text
                            fontFamily={font.family}
                            fontSize={18}
                            fontWeight={700}
                          >
                            Benefits
                          </Text>
                          <Text
                            display="block"
                            mt={5}
                            mb={5}
                            fontFamily={font.family}
                            dangerouslySetInnerHTML={{
                              __html: selectedJobOffer.benefits,
                            }}
                          />
                          <CloseIconWrapper onClick={handleCloseClick}>
                            <CloseIcon />
                          </CloseIconWrapper>
                        </JobOffersDetailsContainer>
                      )}
                  </JobOffersParent>
                </ActiveJobOffers>
              </ActiveJobOffersWrapper>

              <ActiveJobOffersWrapper>
                <ActiveJobOffers>
                  <JobOffersContainer>
                    <Text
                      mt={1}
                      mb={1}
                      fontSize={22}
                      fontWeight={700}
                      fontFamily={font.family}
                    >
                      Notifications
                    </Text>
                  </JobOffersContainer>

                  <Text
                    mt={16}
                    mb={1}
                    fontSize={14}
                    fontWeight={400}
                    fontFamily={font.family}
                    color={colors.neutral_400}
                  >
                    Fri. Feb. 16. 2024
                  </Text>

                  {lastNotificationsData.map((notification, index) => {
                    return (
                      <React.Fragment key={index}>
                        <NotificationContainer margin={'12px 0px'}>
                          <NotificationDescriptionDiv>
                            <Text
                              mt={0}
                              mb={0}
                              fontSize={18}
                              fontWeight={400}
                              fontFamily={font.family}
                              color={'rgba(0, 0, 0, 0.70)'}
                            >
                              {notification.notification}
                            </Text>
                            <Text
                              mt={8}
                              mb={0}
                              fontSize={14}
                              fontWeight={400}
                              fontFamily={font.family}
                              color={colors.neutral_400}
                            >
                              {notification.date}
                            </Text>
                          </NotificationDescriptionDiv>

                          <ButtonIconDiv>
                            <ButtonContainer
                              backgroundColor={colors.primary_500}
                              borderRadius="24"
                              type="button"
                              isDashboardReview
                              // onClick={(
                              //   e: React.MouseEvent<HTMLButtonElement>
                              // ) => {
                              //   e.stopPropagation();
                              //   selectJobToViewCandidates(jobOffer);
                              // }}
                              mt={1}
                            >
                              <ButtonTitle>Review</ButtonTitle>
                            </ButtonContainer>
                            <DeleteContainer
                              onClick={(
                                e: React.MouseEvent<HTMLDivElement>
                              ) => {
                                e.stopPropagation();
                                // handleDeleteButtonClick(jobOffer.id);
                              }}
                            >
                              <DeleteIcon />
                            </DeleteContainer>
                          </ButtonIconDiv>
                        </NotificationContainer>
                      </React.Fragment>
                    );
                  })}

                  <Text
                    mt={20}
                    mb={1}
                    fontSize={14}
                    fontWeight={400}
                    fontFamily={font.family}
                    color={colors.neutral_400}
                  >
                    Thu. Feb. 15. 2024
                  </Text>
                  {notificationsData.map((notification, index) => {
                    return (
                      <React.Fragment key={index}>
                        <NotificationContainer margin={'12px 0px'}>
                          <NotificationDescriptionDiv>
                            <Text
                              mt={0}
                              mb={0}
                              fontSize={18}
                              fontWeight={400}
                              fontFamily={font.family}
                              color={'rgba(0, 0, 0, 0.70)'}
                            >
                              {notification.notification}
                            </Text>
                            <Text
                              mt={8}
                              mb={0}
                              fontSize={14}
                              fontWeight={400}
                              fontFamily={font.family}
                              color={colors.neutral_400}
                            >
                              {notification.date}
                            </Text>
                          </NotificationDescriptionDiv>

                          <ButtonIconDiv>
                            <ButtonContainer
                              backgroundColor={colors.primary_500}
                              borderRadius="24"
                              type="button"
                              isDashboardReview
                              // onClick={(
                              //   e: React.MouseEvent<HTMLButtonElement>
                              // ) => {
                              //   e.stopPropagation();
                              //   selectJobToViewCandidates(jobOffer);
                              // }}
                              mt={1}
                            >
                              <ButtonTitle>Review</ButtonTitle>
                            </ButtonContainer>
                            <DeleteContainer
                              onClick={(
                                e: React.MouseEvent<HTMLDivElement>
                              ) => {
                                e.stopPropagation();
                                // handleDeleteButtonClick(jobOffer.id);
                              }}
                            >
                              <DeleteIcon />
                            </DeleteContainer>
                          </ButtonIconDiv>
                        </NotificationContainer>
                      </React.Fragment>
                    );
                  })}
                </ActiveJobOffers>
              </ActiveJobOffersWrapper>

              <ActiveJobOffersWrapper>
                <ActiveJobOffers>
                  <JobOffersContainer>
                    <Text
                      mt={1}
                      mb={1}
                      fontSize={22}
                      fontWeight={700}
                      fontFamily={font.family}
                    >
                      To Do
                    </Text>
                  </JobOffersContainer>

                  {toDoData.map((toDo, index) => {
                    return (
                      <React.Fragment key={index}>
                        <NotificationContainer
                          margin={'12px 0px'}
                          backgroundColor={colors.secondary_50}
                        >
                          <ToDoDescriptionDiv>
                            <Text
                              mt={0}
                              mb={0}
                              fontSize={18}
                              fontWeight={400}
                              fontFamily={font.family}
                              color={colors.base_black}
                            >
                              {toDo.toDoDescription}
                            </Text>
                            <DueDateDiv>
                              <InfoIconWrapper toDoList>
                                <InfoIcon toDoList />
                              </InfoIconWrapper>
                              <Text
                                mt={0}
                                mb={0}
                                ml={8}
                                fontSize={14}
                                fontWeight={400}
                                fontFamily={font.family}
                                color={colors.neutral_600}
                              >
                                Due {toDo.date}
                              </Text>
                            </DueDateDiv>
                            <NoteDiv>
                              <Text
                                mt={0}
                                mb={0}
                                mr={10}
                                fontSize={14}
                                fontWeight={400}
                                fontFamily={font.family}
                                color={colors.neutral_900}
                              >
                                Note:
                              </Text>
                              <Text
                                mt={0}
                                mb={0}
                                mr={10}
                                fontSize={14}
                                fontWeight={400}
                                fontFamily={font.family}
                                color={colors.neutral_600}
                              >
                                {toDo.note}
                              </Text>
                              <SquareIcon />
                            </NoteDiv>
                          </ToDoDescriptionDiv>

                          <ButtonIconDiv>
                            <ButtonContainer
                              backgroundColor={colors.secondary_500}
                              borderRadius="24"
                              type="button"
                              isDashboardReview
                              // onClick={(
                              //   e: React.MouseEvent<HTMLButtonElement>
                              // ) => {
                              //   e.stopPropagation();
                              //   selectJobToViewCandidates(jobOffer);
                              // }}
                              mt={1}
                            >
                              <ButtonTitle>Continue</ButtonTitle>
                            </ButtonContainer>
                            <DeleteContainer
                              onClick={(
                                e: React.MouseEvent<HTMLDivElement>
                              ) => {
                                e.stopPropagation();
                                // handleDeleteButtonClick(jobOffer.id);
                              }}
                            >
                              <DeleteIcon />
                            </DeleteContainer>
                          </ButtonIconDiv>
                        </NotificationContainer>
                      </React.Fragment>
                    );
                  })}
                </ActiveJobOffers>
              </ActiveJobOffersWrapper>
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
      <AppTutorialModal />
    </LayoutContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { Spinner } from '../../icons/spinner';
import { logOut } from '../../../function-utils/log-out';

interface PaymentSuccessProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = ({
  user,
  setUser,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const sessionId = searchParams.get('session_id');

    const checkPaymentStatus = async () => {
      try {
        if (!sessionId) {
          setError('Invalid session ID.');
          setLoading(false);

          return;
        }

        const response = await axios.get(`${apiRoute}/payment-success`, {
          params: { session_id: sessionId },
        });

        if (
          response.data.message === 'Payment successful and company updated.'
        ) {
          await logOut(setUser, navigate);
        } else {
          setError('Payment status could not be verified.');
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setError('An error occurred while checking your payment status.');
      } finally {
        setLoading(false);
      }
    };

    checkPaymentStatus();

    const timer = setTimeout(() => {
      logOut(setUser);
      navigate('/login');
    }, 8000);

    return () => clearTimeout(timer);
  }, [navigate, searchParams, user]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : error ? (
        <div>
          <h1>Error</h1>
          <p>{error}</p>
        </div>
      ) : (
        <>
          <h1>Payment Successful!</h1>
          <p>You will be logged out and redirected shortly.</p>
        </>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiRoute } from '../../constants/api-constants';

const useCheckTrialPeriod = (userId: string | null) => {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const testing = false;

  const fetchUserData = async () => {
    if (!userId) return;

    try {
      console.log('we are fetching userdata to check');
      if (testing) console.log(`Fetching user data for ID: ${userId}`);
      const response = await axios.get(`${apiRoute}/entity/${userId}`);
      setUser(response.data);
      if (testing) console.log('Fetched user data:', response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (!userId || loading || !user) return;

    if (testing) console.log('User data is available:', user);

    const isEmployee = user.entityType === 'employee';
    const company = isEmployee ? user.company : user;

    if (!company) {
      if (testing)
        console.error('Company details are missing. Unable to proceed.');

      return;
    }

    const currentDate = new Date();
    const timezoneFlexInMillis = 1000 * 3600 * 24;

    if (company.is_on_trial) {
      const createdAt = new Date(company.createdAt);
      const daysDifference =
        (currentDate.getTime() - createdAt.getTime()) / (1000 * 3600 * 24);

      if (daysDifference > 7) {
        if (testing)
          console.log('Trial period expired. Redirecting to Change Plan page.');
        navigate('/change-plan');
      } else {
        if (testing)
          console.log(
            `Trial period still active. ${
              7 - Math.floor(daysDifference)
            } days left.`
          );
      }
    } else {
      // Access last_subscription_payed_at based on user type
      const lastSubscriptionPaidAt = new Date(
        isEmployee
          ? company.last_subscription_payed_at
          : user.last_subscription_payed_at
      );

      // Check if lastSubscriptionPaidAt is a valid date
      if (isNaN(lastSubscriptionPaidAt.getTime())) {
        console.error('Invalid last_subscription_payed_at date');

        return;
      }

      const subscriptionValidUntil = new Date(lastSubscriptionPaidAt);
      subscriptionValidUntil.setMonth(subscriptionValidUntil.getMonth() + 1);

      const validFrom = new Date(
        lastSubscriptionPaidAt.getTime() - timezoneFlexInMillis
      );
      const validUntil = new Date(
        subscriptionValidUntil.getTime() + timezoneFlexInMillis
      );

      if (testing)
        console.log('Subscription Validity Range with flexibility:', {
          validFrom: validFrom.toISOString(),
          validUntil: validUntil.toISOString(),
        });

      if (currentDate >= validFrom && currentDate <= validUntil) {
        if (testing) console.log('Subscription is valid. No action required.');
      } else {
        if (testing)
          console.log(
            'Subscription period expired. Redirecting to Change Plan page.'
          );
        navigate('/change-plan');
      }
    }
  }, [user, navigate, location, loading]);

  return { loading };
};

export default useCheckTrialPeriod;

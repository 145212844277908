import React, { useState, useEffect } from 'react';
import { ButtonContainer } from '../../../login/login.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { fetchAllItems } from '../../../requests/requests';
import {
  MainContentWrapper,
  ButtonWrapper,
  ContentButtonsWrapper,
  LayoutContainer,
  NotificationMessageInput,
  NotificationTypeSelect,
  TextWrapper,
  ContentWrapper,
  InputContainer,
  TitleAndSubtitle,
  CompaniesSelect,
} from './broadcast.styled';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import { Spinner } from '../../../icons/spinner';

interface Company {
  id: string;
  account_type: string;
  email: string;
  name: string;
  website: string;
  phone_number: string;
  industry: string;
  founded: string;
  address: string;
  country: string;
  locations: string;
  tax_number: string;
  employee_count: number;
  specialities: string;
  subscription_plan_id: string | null;
  is_verified: boolean;
  is_on_trial: boolean;
  first_time_logged_in: boolean;
  color_template: string;
  receive_email_notifications: boolean;
  receive_in_app_notifications: boolean;
  receive_edit_notifications: boolean;
  receive_time_off_notifications: boolean;
  profile_picture: string | null;
  last_subscription_payed_at: string;
  createdAt: string;
  updatedAt: string;
}

export const Broadcast = () => {
  const [message, setMessage] = useState('');
  const [companiesData, setCompaniesData] = useState<Company[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [targetUsers, setTargetUsers] = useState('All');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCompanies = async () => {
      setIsLoading(true);
      try {
        const response = await fetchAllItems(`/all-companies`);
        setCompaniesData(response as Company[]);
      } catch (error) {
        console.error('Error fetching companies: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    getCompanies();
  }, []);

  const handleSendNotification = async () => {
    const payload = {
      message,
      selectedCompanies,
      targetUsers,
    };

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${apiRoute}/broadcast-notifications`,
        payload
      );
      console.log('Notification sent successfully: ', response.data);
    } catch (error) {
      console.error('Error sending notification: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedCompanies(selectedOptions);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <MainContentWrapper>
      <ContentWrapper>
        <LayoutContainer height={600}>
          <TitleAndSubtitle direction="column">
            <TextWrapper>
              <Text mt={0} mb={0} fontSize={32} fontFamily={font.family}>
                Send Notification
              </Text>
            </TextWrapper>
          </TitleAndSubtitle>
          <InputContainer>
            <CompaniesSelect
              multiple
              value={selectedCompanies}
              onChange={handleCompanySelect}
            >
              {companiesData.map((company: Company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </CompaniesSelect>

            <NotificationTypeSelect
              value={targetUsers}
              onChange={(e) => setTargetUsers(e.target.value)}
            >
              <option value="All">All Users</option>
              <option value="Admin">Admin Users</option>
            </NotificationTypeSelect>

            <NotificationMessageInput
              placeholder="Enter your notification message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </InputContainer>

          <ContentButtonsWrapper>
            <ButtonWrapper>
              <ButtonContainer
                backgroundColor={colors.primary_400}
                onClick={handleSendNotification}
              >
                <Text
                  mt={0}
                  mb={0}
                  align="center"
                  pl={20}
                  pr={20}
                  fontFamily={font.family}
                  fontWeight={700}
                >
                  Send Notification
                </Text>
              </ButtonContainer>
            </ButtonWrapper>
          </ContentButtonsWrapper>
        </LayoutContainer>
      </ContentWrapper>
    </MainContentWrapper>
  );
};

import { useState, useEffect, useContext, ChangeEvent } from 'react';
import { NotificationsContext } from '../../../context/notification-provider';
import { NOTIFICATION_TYPES } from '../../pages/employees/notification-preferences/notification-types';
import { Notification, UserPreferences, NewNotification } from './interfaces';

export const useNotificationsHook = (
  userId: string,
  preferences: UserPreferences
) => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotificationsHook must be used within a NotificationsProvider'
    );
  }

  const {
    notifications,
    fetchNotifications,
    createNotification,
    updateNotification,
    deleteNotification,
    markAsRead,
  } = context;

  const [newNotification, setNewNotification] = useState<NewNotification>({
    entity_id: '',
    entity_type: '',
    title: '',
    message: '',
    type: '',
  });

  const [userNotifications, setUserNotifications] = useState<Notification[]>(
    []
  );
  const [isNotificationPanelOpen, setNotificationPanelOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      fetchNotifications(userId);
    }
  }, [userId, fetchNotifications]);
  useEffect(() => {
    if (notifications.length > 0 && preferences.receive_in_app_notifications) {
      const filteredNotifications = notifications
        .filter((notification) => notification.entity_id === userId)
        .filter((notification) => {
          if (notification.type === NOTIFICATION_TYPES.BROADCAST) {
            return true;
          }
          if (
            notification.type === NOTIFICATION_TYPES.TIME_OFF_REQUEST &&
            !preferences.receive_time_off_notifications
          ) {
            return false;
          }
          if (
            notification.type === NOTIFICATION_TYPES.PROFILE_EDIT &&
            !preferences.receive_edit_notifications
          ) {
            return false;
          }

          return true;
        })
        .slice(-10); // Only get the last 10 notifications
      setUserNotifications(filteredNotifications);
    }
  }, [
    notifications,
    userId,
    preferences.receive_in_app_notifications,
    preferences.receive_edit_notifications,
    preferences.receive_time_off_notifications,
  ]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof NewNotification
  ) => {
    setNewNotification({
      ...newNotification,
      [field]: e.target.value,
    });
  };

  const handleCreateNotification = async () => {
    try {
      createNotification(newNotification);
      setNewNotification({
        entity_id: '',
        entity_type: '',
        title: '',
        message: '',
        type: '',
      });
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const handleUpdateNotification = async (
    id: number,
    updatedData: Partial<Notification>
  ) => {
    try {
      updateNotification(id, updatedData);
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };

  const handleDeleteNotification = async (id: number) => {
    try {
      deleteNotification(id);
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleMarkAsRead = async (id: number) => {
    try {
      markAsRead(id);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleNotificationClick = (notificationId: number) => {
    handleMarkAsRead(notificationId);
    setNotificationPanelOpen(false);
  };

  const toggleNotificationPanel = () => {
    if (!isNotificationPanelOpen) {
      userNotifications.forEach((notification) => {
        if (!notification.read) {
          handleMarkAsRead(notification.id);
        }
      });
    }
    setNotificationPanelOpen((prevState) => !prevState);
  };

  const unseenCount = userNotifications.filter(
    (notification) => !notification.read
  ).length;

  return {
    notifications,
    userNotifications,
    isNotificationPanelOpen,
    toggleNotificationPanel,
    handleNotificationClick,
    setNotificationPanelOpen,
    unseenCount,
    newNotification,
    setNewNotification,
    handleInputChange,
    handleCreateNotification,
    handleUpdateNotification,
    handleDeleteNotification,
    handleMarkAsRead,
  };
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import { colors } from '../../../style-utils/colors';
import { Text, font, EllipsisText } from '../../../style-utils/text';
import {
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentContainer,
  ContentAndButtonsContainer,
  ContentWrapper,
} from '../../layout/layout-container.styled';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { Sidebar } from '../../layout/sidebar/sidebar';
import {
  ErrorLabel,
  StyledJobInput,
  StyledJobTextArea,
  WrapperDiv,
} from '../jobs/jobs.styled';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
// import { TextAreaInput } from '../../textarea/text-area';
import { DownArrow } from '../../icons/down-arrow';
import { Spinner } from '../../icons/spinner';
import { ConfirmPopup } from '../../confirmation-popup/confirm-popup';
import {
  AddEmployeeFormContainer,
  AddEmployeeHeaderContainer,
  AddEmployeeInputAndErrorContainer,
  AddEmployeeProfilePictureArea,
  AddEmployeeProfilePictureContainer,
  AddEmployeeProfilePictureHeader,
  AddEmployeeSectionContainer,
  AddEmployeeStyledButton,
  AddEmployeeStyledOption,
  AddEmployeeStyledOptionsWrapper,
  AddEmployeeStyledSelectWrapper,
  BasicWrapper,
  CustomUploadLabel,
  DownArrowContainer,
  EducationListWrapper,
  HiddenInputsWrapper,
  HiddenUploadInput,
  StyledIndefiniteContractCheckbox,
  StyledIndefiniteContractCheckboxWrapper,
  ViewAllInputsBtn,
} from './add-employee.styled';
import { countries } from '../../../constants/contries';
import { StyledAddedEmail } from '../job-board/job-board.styled';
import { DeleteButton } from '../jobs/create-job-offer/create-job-offer-flow.styled';
import { BlankImageIcon } from '../../icons/blank-image-icon';
import { EmployeeJobData } from '../../../interfaces/employee-job-interfaces';
import { StyledEmployeeTimeOffRequestAttachedFile } from './add-employee-timeoff-request.styled';
import { SelectDate } from '../applicants/candidates/date-picker';
import { UpArrow } from '../../icons/up-arrow';
import MessagePopup from '../../messages/message-popup/message-popup';

const predefinedSkills = [
  'JavaScript',
  'Java',
  'PHP',
  'Python',
  'NodeJS',
  'React',
  'Angular',
  'Vue',
  'SQL',
  'PostgreSQL',
  'MongoDB',
  'Firebase',
  'NextJS',
];

const jobCategories = ['Full time', 'Part time', 'Contract based'];

const seniorityLevelEntries = ['Intern', 'Junior', 'Intermediate', 'Senior'];

interface InputRefs {
  job_id: React.MutableRefObject<HTMLDivElement | null>;
  first_name: React.MutableRefObject<HTMLDivElement | null>;
  last_name: React.MutableRefObject<HTMLDivElement | null>;
  email: React.MutableRefObject<HTMLDivElement | null>;
  phone_number: React.MutableRefObject<HTMLDivElement | null>;
  address: React.MutableRefObject<HTMLDivElement | null>;
  date_of_birth: React.MutableRefObject<HTMLDivElement | null>;
  date_of_employment: React.MutableRefObject<HTMLDivElement | null>;
  end_of_contract: React.MutableRefObject<HTMLDivElement | null>;
  cv: React.MutableRefObject<HTMLDivElement | null>;
}

export interface NewEmployeeDataTypes {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  address: string;
  country: string;
  date_of_birth: string;
  personal_website: string;
  intro: string;
  education: string[];
  job_position: string;
  salary: string;
  seniority: string;
  contract_type: string;
  date_of_employment: string;
  end_of_contract: string;
  company_experience: string[];
  job_skills: string[];
  company_id: string;
  job_id: string;
  cv: File | string | null;
  cv_text: string;
  portfolio: File | string | null;
  documents: File | string | null;
  profile_picture: File | string | null;
  multiple_documents: string[] | FileList | null;
  remaining_days: number;
  status: string;
  receive_email_notifications: boolean;
  receive_in_app_notifications: boolean;
  receive_edit_notifications: boolean;
  receive_time_off_notifications: boolean;
  gender: string;
  company_cv: File | string | null;
  indefinite_contract: boolean;
}

const newEmployeeDataInitialState = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  address: '',
  country: '',
  date_of_birth: '',
  personal_website: '',
  intro: '',
  education: [],
  job_position: '',
  salary: '',
  seniority: '',
  contract_type: '',
  date_of_employment: '',
  end_of_contract: '',
  company_experience: [],
  job_skills: [],
  company_id: '',
  job_id: '',
  cv: null,
  cv_text: '',
  portfolio: null,
  documents: null,
  profile_picture: null,
  multiple_documents: null,
  remaining_days: 23,
  status: 'active',
  receive_email_notifications: true,
  receive_in_app_notifications: true,
  receive_edit_notifications: true,
  receive_time_off_notifications: true,
  gender: '',
  company_cv: null,
  indefinite_contract: false,
};

interface AddEmployeeProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  // eslint-disable-next-line
  cookies?: Cookie;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const AddEmployee: React.FC<AddEmployeeProps> = ({
  navigate,
  company,
  setUser,
}) => {
  const [newEmployee, setNewEmployee] = useState<NewEmployeeDataTypes>(
    newEmployeeDataInitialState
  );
  const [companyJobs, setCompanyJobs] = useState<EmployeeJobData[]>([]);
  const [selectedJobId, setSelectedJobId] = useState('');
  const [isCVTooBig, setIsCVTooBig] = useState(false);
  const [wrongDateOfBirth, setWrongDateOfBirth] = useState<string>('');
  const [wrongEndOfContract, setWrongEndOfContract] = useState<string>('');
  const [wrongDateOfEmployment, setWrongDateOfEmployment] =
    useState<string>('');
  const [wrongPhoneFormat, setWrongPhoneFormat] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [togglePopup, setTogglePopup] = useState<boolean>(false);
  const [popupMsg, setPopupMsg] = useState<string>('');
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const [education, setEducation] = useState<string>('');
  const [educationList, setEducationList] = useState<string[]>([]);
  const [pastCompany, setPastCompany] = useState<string>('');
  const [pastCompanyList, setPastCompanyList] = useState<string[]>([]);
  const [skill, setSkill] = useState<string>('');
  const [skillList, setSkillList] = useState<string[]>([]);
  const [CVName, setCVName] = useState<string>('');
  const [portfolioName, setPortfolioName] = useState<string>('');
  const [documentsName, setDocumentsName] = useState<string[]>([]);
  const [isSkillDropdownOpen, setIsSkillDropdownOpen] =
    useState<boolean>(false);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] =
    useState<boolean>(false);
  const [isJobPositionDropdownOpen, setIsJobPositionDropdownOpen] =
    useState<boolean>(false);
  const [isJobCategoryDropdownOpen, setIsJobCategoryDropdownOpen] =
    useState<boolean>(false);
  const [isSeniorityLevelDropdownOpen, setIsSeniorityLevelDropdownOpen] =
    useState<boolean>(false);
  const [country, setCountry] = useState<string>('');
  const [jobPosition, setJobPosition] = useState<string>('');
  const [jobCategory, setJobCategory] = useState<string>('Full time');
  const [seniorityLevel, setSeniorityLevel] = useState<string>('');
  const [cvFile, setCvFile] = useState<File | null>(null);
  const [portfolioFile, setPortfolioFile] = useState<File | null>(null);
  const [multipleFiles, setMultipleFiles] = useState<File[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const skillsDropdownRef = useRef<HTMLDivElement>(null);
  const countryDropdownRef = useRef<HTMLDivElement>(null);
  const jobPositionDropdownRef = useRef<HTMLDivElement>(null);
  const jobCategoryDropdownRef = useRef<HTMLDivElement>(null);
  const seniorityLevelDropdownRef = useRef<HTMLDivElement>(null);
  const inputRefs: InputRefs = {
    job_id: useRef(null),
    first_name: useRef(null),
    last_name: useRef(null),
    email: useRef(null),
    phone_number: useRef(null),
    address: useRef(null),
    date_of_birth: useRef(null),
    date_of_employment: useRef(null),
    end_of_contract: useRef(null),
    cv: useRef(null),
  };
  const [areAdditionalInputsVisible, setAreAdditionalInputsVisible] =
    useState<boolean>(false);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  // const jobOffersFromDB = async () => {
  //   if (company.id) {
  //     const response = await fetchAllItems(`company/jobs/${company.id}`);
  //     setCompanyJobs(response as JobOfferWithCompanyData[]);
  //   }
  // };

  const baseURL = apiRoute;

  const jobOffersFromDB = async () => {
    let response: EmployeeJobData[] = [];

    if (company.id) {
      axios
        .get(`${baseURL}/employee_job_positions/${company.id}`)
        .then((res) => {
          if (res.data && res.data.data) {
            response = res.data.data;
          }
          setCompanyJobs(response);
        })
        .catch((error) => {
          console.error('Error fetching job offers:', error);
          setCompanyJobs([]);
        });
    } else {
      setCompanyJobs(response);
    }
  };

  useEffect(() => {
    jobOffersFromDB();
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const height = useWindowHeight();

  const handleOnJobChange = (jobId: string, jobPosition: string) => {
    // console.log(e.target.value);
    // const jobId = e.target.value;
    setSelectedJobId(jobId);
    setJobPosition(jobPosition);
    setIsJobPositionDropdownOpen(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;

    if (name === 'cv' || name === 'portfolio' || name === 'documents') {
      // Check if the event is from file input and has files
      if (event.target instanceof HTMLInputElement && event.target.files) {
        const files = event.target.files;
        const fileNames = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          fileNames.push(file.name); // Push file name to array
        }
        // Update newEmployee state based on input name
        if (name === 'cv') {
          if (files[0]) {
            setCVName(fileNames[0]);
            const fileSizeMB = files[0].size / 1024 / 1024;
            setIsCVTooBig(fileSizeMB > 25);
          } else {
            setNewEmployee({ ...newEmployee, [name]: null });

            return;
          }
        } else if (name === 'portfolio') {
          if (files[0]) {
            setPortfolioName(fileNames[0]);
          } else {
            setNewEmployee({ ...newEmployee, [name]: null });

            return;
          }
        } else if (name === 'documents') {
          setNewEmployee((prevEmployee) => ({
            ...prevEmployee,
            multiple_documents: files,
          }));
          setDocumentsName(fileNames);
        }
        // Update newEmployee state with the selected file
        setNewEmployee((prevEmployee) => ({
          ...prevEmployee,
          [name]: files[0],
        }));
      }
    } else {
      if (name === 'phone_number') {
        const numberPattern = /^\+?\d+$/;
        if (!numberPattern.test(value)) {
          setWrongPhoneFormat(true);
        } else {
          setWrongPhoneFormat(false);
        }
      }
      setNewEmployee({ ...newEmployee, [name]: value });
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const invalidFields = validateNewJobOfferData(newEmployee);
    setInvalidFieldsInputArray(invalidFields);

    console.log('invalid Fiels array = ', invalidFields);

    if (invalidFields.length !== 0) {
      setIsLoading(false);
      const refKey = invalidFields[0];
      inputRefs[refKey as keyof InputRefs].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      return;
    }

    if (
      newEmployee.date_of_employment === '' &&
      newEmployee.end_of_contract === ''
    ) {
      setWrongEndOfContract('');
    }

    if (
      newEmployee.date_of_employment !== '' &&
      newEmployee.end_of_contract !== '' &&
      newEmployee.date_of_employment <= newEmployee.end_of_contract
    ) {
      setWrongEndOfContract(
        'End of contract cannot be before or the same as date of employment'
      );
    }

    if (
      isCVTooBig ||
      wrongDateOfBirth ||
      wrongPhoneFormat ||
      wrongEndOfContract ||
      wrongDateOfEmployment
    )
      return;
    setIsLoading(true);

    if (selectedJobId) {
      newEmployee.job_id = selectedJobId;
      if (company) {
        newEmployee.company_id = company.id;
      }
    }

    if (educationList) {
      newEmployee.education = educationList;
    }

    if (pastCompanyList) {
      newEmployee.company_experience = pastCompanyList;
    }

    if (skillList) {
      newEmployee.job_skills = skillList;
    }

    try {
      const formData = new FormData();

      if (newEmployee.cv) {
        formData.append('cv', newEmployee.cv);
      }
      if (newEmployee.portfolio) {
        formData.append('portfolio', newEmployee.portfolio);
      }
      if (cvFile) {
        formData.append('cv', cvFile);
      }
      if (portfolioFile) {
        formData.append('portfolio', portfolioFile);
      }
      if (newEmployee.documents) {
        formData.append('documents', newEmployee.documents);
      }
      if (newEmployee.profile_picture) {
        formData.append('profile_picture', newEmployee.profile_picture);
      }

      if (newEmployee.multiple_documents) {
        for (let i = 0; i < newEmployee.multiple_documents.length; i++) {
          const file = newEmployee.multiple_documents[i];
          formData.append('multiple_documents', file);
        }
      }

      if (multipleFiles) {
        for (let i = 0; i < multipleFiles.length; i++) {
          const file = multipleFiles[i];
          formData.append('multiple_documents', file);
        }
      }

      formData.append('first_name', newEmployee.first_name);
      formData.append('last_name', newEmployee.last_name);
      formData.append('email', newEmployee.email);
      formData.append('phone_number', newEmployee.phone_number);
      formData.append('address', newEmployee.address);
      formData.append('country', country);
      formData.append('date_of_birth', newEmployee.date_of_birth);
      formData.append('personal_website', newEmployee.personal_website);
      formData.append('intro', newEmployee.intro);
      newEmployee.education.map((value) => {
        formData.append('education', value);
      });
      formData.append('job_id', newEmployee.job_id);
      formData.append('salary', newEmployee.salary);
      formData.append('contract_type', jobCategory);
      formData.append('seniority', seniorityLevel);
      formData.append('date_of_employment', newEmployee.date_of_employment);
      formData.append('end_of_contract', newEmployee.end_of_contract);
      newEmployee.company_experience.map((value) => {
        formData.append('company_experience', value);
      });
      newEmployee.job_skills.map((value) => {
        formData.append('job_skills', value);
      });
      formData.append('company_id', company.id);
      formData.append(
        'indefinite_contract',
        newEmployee.indefinite_contract ? 'true' : 'false'
      );
      // formData.append('job_position', newEmployee.job_position);

      console.log('Form data = ', formData);

      const response = await axios.post(`${apiRoute}/add-employee`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 200) {
        console.log('New emploee successfully created:', response.data);
        handleTogglePopup('Employee successfully created.');
        setNewEmployee(newEmployeeDataInitialState);
        setEducationList([]);
        setPastCompanyList([]);
        setSkillList([]);
        setCountry('');
        setJobCategory('');
        setJobPosition('');
        setSeniorityLevel('');
        if (formRef.current) {
          formRef.current.reset();
        }
      } else {
        console.error('Unexpected response status:', response.status);
        handleTogglePopup('Unexpected error occured, please try again');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('Axios error:', axiosError.message);
        if (axiosError.response) {
          console.error('Response error:', axiosError.response.data);
          setWrongEndOfContract('');
        } else if (axiosError.request) {
          console.error('Request error:', axiosError.request);
        }
      } else {
        console.error('Non-Axios error:', error);
      }
    } finally {
      // setNewEmployee(newEmployeeDataInitialState);
      // setEducationList([]);
      // setPastCompanyList([]);
      // setSkillList([]);
      // setCountry('');
      // setJobCategory('');
      // setJobPosition('');
      // setSeniorityLevel('');
      // if (formRef.current) {
      //   formRef.current.reset();
      // }
      // navigate && navigate(`/${name}/hiring/job-offers`);
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewJobOfferData = (data: any) => {
    const requiredFields = [
      // 'job_id',
      'first_name',
      'last_name',
      'email',
      'phone_number',
      // 'address',
      // 'date_of_birth',
    ];
    const emptyFields: string[] = [];

    if (wrongDateOfBirth) {
      emptyFields.push('date_of_birth');
    }
    if (wrongPhoneFormat) {
      emptyFields.push('phone_number');
    }
    if (wrongEndOfContract) {
      emptyFields.push('end_of_contract');
    }
    if (isCVTooBig) {
      emptyFields.push('cv');
    }

    requiredFields.forEach((field) => {
      if (data[field].length === 0) {
        emptyFields.push(field);
      }
    });

    return emptyFields;
  };

  const handleTogglePopup = (msg: string) => {
    setPopupMsg(msg);
    setTogglePopup(true);
  };

  const handleToggle = (currentState: boolean) => {
    setTogglePopup(currentState);
  };

  const handleAddEducation = () => {
    if (education) {
      setEducationList((prevEducationList) => [
        ...prevEducationList,
        education,
      ]);
      setEducation('');
    }
  };

  const handleAddPastCompany = () => {
    if (pastCompany) {
      setPastCompanyList((prevPastCompanyList) => [
        ...prevPastCompanyList,
        pastCompany,
      ]);
      setPastCompany('');
    }
  };

  const handleAddEducationKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && education.trim() !== '') {
      e.preventDefault();
      setEducationList((prevEducationList) => [
        ...prevEducationList,
        education,
      ]);

      setEducation('');
    }
  };

  const handleAddPastCompanyKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && pastCompany.trim() !== '') {
      e.preventDefault();
      setPastCompanyList((prevPastCompanyList) => [
        ...prevPastCompanyList,
        pastCompany,
      ]);

      setPastCompany('');
    }
  };

  const handleCustomSkillAddKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && !skillList.includes(skill)) {
      e.preventDefault();
      setSkillList((prevSkillList) => [...prevSkillList, skill]);

      setSkill('');
    }
  };

  const handleEducationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEducation(e.target.value);
  };

  const handlePastCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPastCompany(e.target.value);
  };

  const handleCustomSkillChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSkill(e.target.value);
  };

  const handleRemoveEducation = (educationToRemove: string) => {
    setEducationList(
      educationList.filter((education) => education !== educationToRemove)
    );
  };

  const handleRemovePastCompany = (pastCompanyToRemove: string) => {
    setPastCompanyList(
      pastCompanyList.filter(
        (pastCompany) => pastCompany !== pastCompanyToRemove
      )
    );
  };

  const handleRemoveSkill = (skillToRemove: string) => {
    setSkillList(skillList.filter((skill) => skill !== skillToRemove));
  };

  const handleSkillSelect = (skill: string) => {
    // Check if the skill is not already selected
    if (!skillList.includes(skill)) {
      setSkillList((prevSkillList) => [...prevSkillList, skill]);

      setSkill('');
    }
    setIsSkillDropdownOpen(false);
  };

  const handleDisableEnterFormSubmission = (
    e: React.KeyboardEvent<HTMLFormElement>
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleDropdown = (
    e: React.MouseEvent<HTMLDivElement>,
    dropdownFor: string
  ) => {
    e.stopPropagation();
    switch (dropdownFor) {
      case 'skills':
        setIsSkillDropdownOpen(!isSkillDropdownOpen);

        return;
      case 'country':
        setIsCountryDropdownOpen(!isCountryDropdownOpen);

        return;
      case 'job_category':
        setIsJobCategoryDropdownOpen(!isJobCategoryDropdownOpen);

        return;
      case 'seniority_level':
        setIsSeniorityLevelDropdownOpen(!isSeniorityLevelDropdownOpen);

        return;
      case 'job_position':
        setIsJobPositionDropdownOpen(!isJobPositionDropdownOpen);

        return;
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      (skillsDropdownRef.current &&
        !skillsDropdownRef.current.contains(e.target as Node)) ||
      (countryDropdownRef.current &&
        !countryDropdownRef.current.contains(e.target as Node)) ||
      (jobCategoryDropdownRef.current &&
        !jobCategoryDropdownRef.current.contains(e.target as Node)) ||
      (seniorityLevelDropdownRef.current &&
        !seniorityLevelDropdownRef.current.contains(e.target as Node)) ||
      (jobPositionDropdownRef.current &&
        !jobPositionDropdownRef.current.contains(e.target as Node))
    ) {
      setIsCountryDropdownOpen(false);
      setIsSkillDropdownOpen(false);
      setIsJobCategoryDropdownOpen(false);
      setIsSeniorityLevelDropdownOpen(false);
      setIsJobPositionDropdownOpen(false);
    }
  };

  const handleSelectCountry = (country: string) => {
    setCountry(country);
    setIsCountryDropdownOpen(false);
  };

  const handleSelectJobCategory = (category: string) => {
    setJobCategory(category);
    setIsJobCategoryDropdownOpen(false);
  };

  const handleSelectSeniorityLevel = (level: string) => {
    setSeniorityLevel(level);
    setIsSeniorityLevelDropdownOpen(false);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;

    if (name === 'cv') {
      if (files && files[0]) {
        const cv = files[0];
        const fileType = cv.type;

        if (fileType !== 'application/pdf') {
          setPopUp({
            text: 'Only PDF files are allowed for CV uploads',
            type: 'error',
          });

          return;
        }

        setCvFile(cv);

        const fileSizeMB = cv.size / 1024 / 1024;
        if (fileSizeMB > 25) {
          setIsCVTooBig(true);
        } else {
          setIsCVTooBig(false);
        }
      }
    }

    if (name === 'portfolio') {
      if (files) {
        const portfolio = files[0];
        setPortfolioFile(portfolio);
      }
    }

    if (name === 'documents') {
      const emptyArray = [];
      if (files) {
        for (let i = 0; i < files.length; i++) {
          emptyArray.push(files[i]);
        }
        setMultipleFiles(emptyArray);
      }
    }
  };

  const handleRemoveCV = () => {
    setCvFile(null);
    setIsCVTooBig(false);
  };

  const handleRemovePortfolio = () => {
    setPortfolioFile(null);
  };

  const handleRemoveFile = (fileToDelete: File) => {
    const updatedFiles = multipleFiles.filter((file) => file !== fileToDelete);
    setMultipleFiles(updatedFiles);
  };

  const handleDateChange = (value: Date | null, name: string) => {
    console.log('Selected expiration date: ', value);
    if (value) {
      if (name === 'date_of_birth') {
        const selectedDate = new Date(value);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to the start of today

        if (selectedDate.getFullYear() > 9999) {
          setWrongDateOfBirth('Please enter a valid date.');
        } else {
          setWrongDateOfBirth('');
        }

        // Calculate min and max age boundaries
        const minAgeDate = new Date(
          today.getFullYear() - 60,
          today.getMonth(),
          today.getDate()
        );
        const maxAgeDate = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate()
        );

        if (selectedDate > today) {
          setWrongDateOfBirth('Date of birth cannot be in the future.');
        } else if (selectedDate < minAgeDate) {
          setWrongDateOfBirth('Employee cannot be older than 60 years.');
        } else if (selectedDate > maxAgeDate) {
          setWrongDateOfBirth('Employee must be at least 18 years old.');
        } else {
          setWrongDateOfBirth('');
        }
      }
      if (name === 'date_of_employment') {
        const selectedDate = new Date(value);
        const endOfContractDate = new Date(newEmployee.end_of_contract);
        selectedDate.setHours(0, 0, 0, 0);
        endOfContractDate.setHours(0, 0, 0, 0);

        if (selectedDate.getFullYear() > 9999) {
          setWrongDateOfEmployment('Please enter a valid date.');
        } else {
          setWrongDateOfEmployment('');
        }

        if (selectedDate >= endOfContractDate) {
          setWrongEndOfContract(
            'End of contract date cannot be before or the same as date of employment.'
          );
        } else {
          setWrongEndOfContract('');
        }
      }
      if (name === 'end_of_contract') {
        console.log(newEmployee.date_of_employment);
        if (newEmployee.date_of_employment === '') {
          setWrongEndOfContract('Please select a date of employement first.');
        } else {
          setWrongEndOfContract('');
          const selectedDate = new Date(value);
          const employmentDate = new Date(newEmployee.date_of_employment);
          selectedDate.setHours(0, 0, 0, 0);
          employmentDate.setHours(0, 0, 0, 0);

          if (selectedDate.getFullYear() > 9999) {
            setWrongEndOfContract('Please enter a valid date.');
          } else {
            setWrongEndOfContract('');
          }

          if (selectedDate <= employmentDate) {
            setWrongEndOfContract(
              'End of contract date cannot be before or the same as date of employment.'
            );
          } else {
            setWrongEndOfContract('');
          }
        }
      }
      const formattedDate = formatDate(value);
      setNewEmployee({ ...newEmployee, [name]: formattedDate });
    }
  };

  function formatDate(value: Date) {
    if (value) {
      const dateObject = new Date(value);

      return dateObject.toISOString().split('T')[0];
    }

    return null;
  }

  const handleDateClear = (name: string) => {
    setNewEmployee((prevState) => {
      const updatedEmployee = { ...prevState, [name]: '' };

      if (name === 'date_of_birth') {
        setWrongDateOfBirth('');
        setInvalidFieldsInputArray((prevArray) =>
          prevArray.filter((field) => field !== 'date_of_birth')
        );
      }

      return updatedEmployee;
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={company} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={company}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ContentAndButtonsContainer
              alignItems="center"
              bgColor={colors.creme_100}
            >
              {isLoading && <Spinner />}
              {!isLoading && (
                <form
                  onSubmit={handleSubmit}
                  ref={formRef}
                  onKeyDown={handleDisableEnterFormSubmission}
                >
                  <AddEmployeeHeaderContainer>
                    <Text
                      fontSize={30}
                      mb={0}
                      fontWeight={700}
                      fontFamily={font.family}
                    >
                      Add New Employee
                    </Text>
                    <Text fontSize={14} mt={6} fontFamily={font.family}>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </Text>
                  </AddEmployeeHeaderContainer>
                  <AddEmployeeFormContainer>
                    <AddEmployeeProfilePictureArea
                      onDrop={(e) => {
                        e.preventDefault();
                      }}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <AddEmployeeProfilePictureContainer
                        onClick={() => {
                          const input = document.createElement('input');
                          input.type = 'file';
                          input.accept = 'image/*';
                          // eslint-disable-next-line
                          input.onchange = (e: any) => {
                            const file = e.target.files[0];
                            setNewEmployee({
                              ...newEmployee,
                              profile_picture: file,
                            });
                          };
                          input.click();
                        }}
                        onDrop={(e) => {
                          e.preventDefault();
                          const file = e.dataTransfer.files[0];
                          if (file && file.type.startsWith('image/')) {
                            // Check if file is an image
                            setNewEmployee({
                              ...newEmployee,
                              profile_picture: file,
                            });
                          } else {
                            console.error(
                              'Invalid file type. Please drop an image file.'
                            );
                          }
                        }}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        {!newEmployee.profile_picture && <BlankImageIcon />}
                        {newEmployee.profile_picture && (
                          <img
                            src={URL.createObjectURL(
                              newEmployee.profile_picture as File
                            )}
                            alt="profile picture"
                          />
                        )}
                      </AddEmployeeProfilePictureContainer>
                      <AddEmployeeProfilePictureHeader>
                        <Text
                          margin="0"
                          fontSize={18}
                          fontWeight={700}
                          fontFamily={font.family}
                          color={colors.base_black}
                          lineHeight="21.6px"
                        >
                          Select or Drop your image here
                        </Text>
                        <AddEmployeeStyledButton
                          bgColor={colors.primary_100}
                          color={colors.base_black}
                          padding="8px 24px"
                          borderRadius="8px"
                          width="153px"
                          type="button"
                          onClick={() => {
                            setNewEmployee({
                              ...newEmployee,
                              profile_picture: null,
                            });
                          }}
                        >
                          Remove image
                        </AddEmployeeStyledButton>
                      </AddEmployeeProfilePictureHeader>
                    </AddEmployeeProfilePictureArea>
                    <AddEmployeeSectionContainer>
                      <Text
                        margin="0"
                        fontSize={33}
                        fontWeight={700}
                        fontFamily={font.family}
                        color={colors.base_black}
                        lineHeight="39.6px"
                      >
                        Personal
                      </Text>
                      <WrapperDiv gap="16px">
                        <AddEmployeeInputAndErrorContainer
                          ref={inputRefs.first_name}
                          width="50%"
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            First name
                          </Text>
                          <StyledJobInput
                            width="100%"
                            type="text"
                            name="first_name"
                            placeholder={`Enter first name`}
                            onChange={handleInputChange}
                            value={newEmployee.first_name}
                          />
                          {invalidFieldsInputArray.includes('first_name') && (
                            <ErrorLabel>Please enter first name</ErrorLabel>
                          )}
                        </AddEmployeeInputAndErrorContainer>
                        <AddEmployeeInputAndErrorContainer
                          ref={inputRefs.last_name}
                          width="50%"
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Last name
                          </Text>
                          <StyledJobInput
                            width="100%"
                            type="text"
                            name="last_name"
                            placeholder={`Enter last name`}
                            onChange={handleInputChange}
                            value={newEmployee.last_name}
                          />
                          {invalidFieldsInputArray.includes('last_name') && (
                            <ErrorLabel>Please enter last name</ErrorLabel>
                          )}
                        </AddEmployeeInputAndErrorContainer>
                      </WrapperDiv>
                      <WrapperDiv gap="16px">
                        <AddEmployeeInputAndErrorContainer
                          ref={inputRefs.email}
                          width="50%"
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Email
                          </Text>
                          <StyledJobInput
                            width="100%"
                            type="email"
                            name="email"
                            placeholder={`Enter email`}
                            onChange={handleInputChange}
                            value={newEmployee.email}
                          />
                          {invalidFieldsInputArray.includes('email') && (
                            <ErrorLabel>Please enter a valid email</ErrorLabel>
                          )}
                        </AddEmployeeInputAndErrorContainer>
                        <AddEmployeeInputAndErrorContainer
                          ref={inputRefs.phone_number}
                          width="50%"
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Phone number
                          </Text>
                          <StyledJobInput
                            width="100%"
                            type="text"
                            name="phone_number"
                            placeholder={`Enter phone number`}
                            onChange={handleInputChange}
                            value={newEmployee.phone_number}
                          />
                          {invalidFieldsInputArray.includes('phone_number') && (
                            <ErrorLabel>
                              Please enter a valid phone number
                            </ErrorLabel>
                          )}
                          {wrongPhoneFormat && (
                            <ErrorLabel>Please enter only numbers</ErrorLabel>
                          )}
                        </AddEmployeeInputAndErrorContainer>
                      </WrapperDiv>
                      <WrapperDiv gap="16px" alignItems="center">
                        <AddEmployeeInputAndErrorContainer
                          width="50%"
                          ref={inputRefs.address}
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Address
                          </Text>
                          <StyledJobInput
                            width="100%"
                            type="text"
                            name="address"
                            placeholder={`Enter address`}
                            onChange={handleInputChange}
                            value={newEmployee.address}
                          />
                          {invalidFieldsInputArray.includes('address') && (
                            <ErrorLabel>Please enter an address</ErrorLabel>
                          )}
                        </AddEmployeeInputAndErrorContainer>
                        <BasicWrapper width="50%">
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Country
                          </Text>
                          <AddEmployeeStyledSelectWrapper
                            onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                              handleDropdown(e, 'country')
                            }
                            width="100%"
                            padding="14px 16px"
                          >
                            <Text
                              fontFamily={font.family}
                              color={colors.neutral_900}
                              margin="0"
                              fontSize={14}
                              lineHeight="16.8"
                            >
                              {country ? country : 'Select a country'}
                            </Text>
                            {isCountryDropdownOpen && (
                              <AddEmployeeStyledOptionsWrapper
                                ref={countryDropdownRef}
                              >
                                {countries.map((country, index) => {
                                  return (
                                    <AddEmployeeStyledOption
                                      key={index}
                                      onClick={(
                                        e: React.MouseEvent<HTMLDivElement>
                                      ) => {
                                        e.stopPropagation();
                                        handleSelectCountry(country);
                                      }}
                                    >
                                      {country}
                                    </AddEmployeeStyledOption>
                                  );
                                })}
                              </AddEmployeeStyledOptionsWrapper>
                            )}
                            <DownArrowContainer
                              right="20px"
                              top="14px"
                              isOpen={isCountryDropdownOpen}
                              height="16px"
                              width="16px"
                            >
                              <DownArrow />
                            </DownArrowContainer>
                          </AddEmployeeStyledSelectWrapper>
                        </BasicWrapper>
                      </WrapperDiv>
                      <WrapperDiv>
                        <AddEmployeeInputAndErrorContainer
                          width="50%"
                          ref={inputRefs.date_of_birth}
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Date of birth
                          </Text>
                          <SelectDate
                            onChange={(value) =>
                              handleDateChange(value, 'date_of_birth')
                            }
                            onClean={() => handleDateClear('date_of_birth')}
                            style={{
                              width: '100%',
                              borderRadius: '8px',
                              borderStyle: 'none',
                              height: '48px',
                              padding: '0 12px',
                              backgroundColor: colors.neutral_50,
                            }}
                            defaultValue={new Date(newEmployee.date_of_birth)}
                          />
                          {wrongDateOfBirth && (
                            <ErrorLabel>{wrongDateOfBirth}</ErrorLabel>
                          )}
                          {invalidFieldsInputArray.includes('date_of_birth') &&
                            !wrongDateOfBirth && (
                              <ErrorLabel>
                                Please enter a valid date of birth
                              </ErrorLabel>
                            )}
                        </AddEmployeeInputAndErrorContainer>
                        {areAdditionalInputsVisible && (
                          <AddEmployeeInputAndErrorContainer width="50%">
                            <Text
                              mt={0}
                              mb={8}
                              fontSize={18}
                              fontWeight={700}
                              fontFamily={font.family}
                            >
                              Personal website
                            </Text>
                            <StyledJobInput
                              width="100%"
                              type="text"
                              name="personal_website"
                              placeholder={`www.example.com`}
                              onChange={handleInputChange}
                              value={newEmployee.personal_website}
                            />
                          </AddEmployeeInputAndErrorContainer>
                        )}
                      </WrapperDiv>
                      {areAdditionalInputsVisible && (
                        <HiddenInputsWrapper>
                          <AddEmployeeInputAndErrorContainer>
                            <Text
                              mt={0}
                              mb={8}
                              fontSize={18}
                              fontWeight={700}
                              fontFamily={font.family}
                            >
                              Intro
                            </Text>
                            <StyledJobTextArea
                              // charLength={newEmployee.intro.length}
                              name="intro"
                              value={newEmployee.intro}
                              placeholder="Enter Intro"
                              onChange={handleInputChange}
                              // height={120}
                              width="100%"
                            />
                          </AddEmployeeInputAndErrorContainer>
                        </HiddenInputsWrapper>
                      )}
                    </AddEmployeeSectionContainer>
                    {areAdditionalInputsVisible && (
                      <HiddenInputsWrapper>
                        <AddEmployeeSectionContainer>
                          <Text
                            margin="0"
                            fontSize={33}
                            fontWeight={700}
                            fontFamily={font.family}
                            color={colors.base_black}
                            lineHeight="39.6px"
                          >
                            Education
                          </Text>
                          <AddEmployeeInputAndErrorContainer>
                            <Text
                              mt={0}
                              mb={8}
                              fontSize={18}
                              fontWeight={700}
                              fontFamily={font.family}
                            >
                              School, college, or university.
                            </Text>
                            <WrapperDiv justifyContent="space-between">
                              <StyledJobInput
                                width="78%"
                                type="text"
                                name="education"
                                placeholder={`School, college, or university.`}
                                value={education}
                                onChange={handleEducationChange}
                                onKeyDown={handleAddEducationKeyDown}
                              />
                              <AddEmployeeStyledButton
                                type="button"
                                padding="12px 24px"
                                bgColor={colors.primary_500}
                                color={colors.base_white}
                                borderRadius="8px"
                                onClick={handleAddEducation}
                              >
                                Add new
                              </AddEmployeeStyledButton>
                            </WrapperDiv>
                            {educationList.length !== 0 && (
                              <EducationListWrapper>
                                {educationList.map((education, index) => (
                                  <StyledAddedEmail key={index}>
                                    <Text
                                      fontFamily={font.family}
                                      color={colors.base_black}
                                      margin="0"
                                    >
                                      {education}
                                    </Text>
                                    <DeleteButton
                                      onClick={() =>
                                        handleRemoveEducation(education)
                                      }
                                    >
                                      &#10006;
                                    </DeleteButton>
                                  </StyledAddedEmail>
                                ))}
                              </EducationListWrapper>
                            )}
                          </AddEmployeeInputAndErrorContainer>
                        </AddEmployeeSectionContainer>
                        <AddEmployeeSectionContainer>
                          <Text
                            margin="0"
                            fontSize={33}
                            fontWeight={700}
                            fontFamily={font.family}
                            color={colors.base_black}
                            lineHeight="39.6px"
                            pt={40}
                          >
                            Application
                          </Text>
                          <WrapperDiv gap="16px">
                            <AddEmployeeInputAndErrorContainer
                              width="75%"
                              isRelative
                            >
                              <Text
                                mt={0}
                                mb={8}
                                fontSize={18}
                                fontWeight={700}
                                fontFamily={font.family}
                              >
                                Job Position
                              </Text>
                              <AddEmployeeStyledSelectWrapper
                                onClick={(
                                  e: React.MouseEvent<HTMLDivElement>
                                ) => handleDropdown(e, 'job_position')}
                                width="100%"
                                padding="14px 16px"
                              >
                                <Text
                                  fontFamily={font.family}
                                  color={colors.neutral_900}
                                  margin="0"
                                  fontSize={14}
                                  lineHeight="16.8"
                                >
                                  {jobPosition
                                    ? jobPosition
                                    : 'Select a job position'}
                                </Text>
                                {isJobPositionDropdownOpen && (
                                  <AddEmployeeStyledOptionsWrapper
                                    ref={jobPositionDropdownRef}
                                  >
                                    {companyJobs.map((job, index) => {
                                      return (
                                        <AddEmployeeStyledOption
                                          key={index}
                                          onClick={(
                                            e: React.MouseEvent<HTMLDivElement>
                                          ) => {
                                            e.stopPropagation();
                                            handleOnJobChange(
                                              job.id,
                                              job.job_position
                                            );
                                          }}
                                        >
                                          {job.job_position}
                                        </AddEmployeeStyledOption>
                                      );
                                    })}
                                  </AddEmployeeStyledOptionsWrapper>
                                )}
                                <DownArrowContainer
                                  right="20px"
                                  top="14px"
                                  isOpen={isJobPositionDropdownOpen}
                                  height="16px"
                                  width="16px"
                                >
                                  <DownArrow />
                                </DownArrowContainer>
                              </AddEmployeeStyledSelectWrapper>
                              {invalidFieldsInputArray.includes('job_id') && (
                                <ErrorLabel>
                                  Please pick the employee&apos;s job position
                                </ErrorLabel>
                              )}
                            </AddEmployeeInputAndErrorContainer>
                            <AddEmployeeInputAndErrorContainer width="25%">
                              <Text
                                mt={0}
                                mb={8}
                                fontSize={18}
                                fontWeight={700}
                                fontFamily={font.family}
                              >
                                Salary
                              </Text>
                              <StyledJobInput
                                width="100%"
                                type="text"
                                name="salary"
                                placeholder={`Enter salary`}
                                onChange={handleInputChange}
                                value={newEmployee.salary}
                              />
                            </AddEmployeeInputAndErrorContainer>
                          </WrapperDiv>
                          <WrapperDiv gap="16px">
                            <AddEmployeeInputAndErrorContainer
                              width="50%"
                              isRelative
                            >
                              <Text
                                mt={0}
                                mb={8}
                                fontSize={18}
                                fontWeight={700}
                                fontFamily={font.family}
                              >
                                Job Category
                              </Text>
                              <AddEmployeeStyledSelectWrapper
                                onClick={(
                                  e: React.MouseEvent<HTMLDivElement>
                                ) => handleDropdown(e, 'job_category')}
                                width="100%"
                                padding="14px 16px"
                              >
                                <Text
                                  fontFamily={font.family}
                                  color={colors.neutral_900}
                                  margin="0"
                                  fontSize={14}
                                  lineHeight="16.8"
                                >
                                  {jobCategory}
                                </Text>
                                {isJobCategoryDropdownOpen && (
                                  <AddEmployeeStyledOptionsWrapper
                                    ref={jobCategoryDropdownRef}
                                  >
                                    {jobCategories.map((category, index) => {
                                      return (
                                        <AddEmployeeStyledOption
                                          key={index}
                                          onClick={(
                                            e: React.MouseEvent<HTMLDivElement>
                                          ) => {
                                            e.stopPropagation();
                                            handleSelectJobCategory(category);
                                          }}
                                        >
                                          {category}
                                        </AddEmployeeStyledOption>
                                      );
                                    })}
                                  </AddEmployeeStyledOptionsWrapper>
                                )}
                                <DownArrowContainer
                                  right="20px"
                                  top="14px"
                                  isOpen={isJobCategoryDropdownOpen}
                                  height="16px"
                                  width="16px"
                                >
                                  <DownArrow />
                                </DownArrowContainer>
                              </AddEmployeeStyledSelectWrapper>
                            </AddEmployeeInputAndErrorContainer>
                            <AddEmployeeInputAndErrorContainer
                              width="50%"
                              isRelative
                            >
                              <Text
                                mt={0}
                                mb={8}
                                fontSize={18}
                                fontWeight={700}
                                fontFamily={font.family}
                              >
                                Seniority Level
                              </Text>
                              <AddEmployeeStyledSelectWrapper
                                onClick={(
                                  e: React.MouseEvent<HTMLDivElement>
                                ) => handleDropdown(e, 'seniority_level')}
                                width="100%"
                                padding="14px 16px"
                              >
                                <Text
                                  fontFamily={font.family}
                                  color={colors.neutral_900}
                                  margin="0"
                                  fontSize={14}
                                  lineHeight="16.8"
                                >
                                  {seniorityLevel
                                    ? seniorityLevel
                                    : 'Select a seniority level'}
                                </Text>
                                {isSeniorityLevelDropdownOpen && (
                                  <AddEmployeeStyledOptionsWrapper
                                    ref={seniorityLevelDropdownRef}
                                  >
                                    {seniorityLevelEntries.map(
                                      (level, index) => {
                                        return (
                                          <AddEmployeeStyledOption
                                            key={index}
                                            onClick={(
                                              e: React.MouseEvent<HTMLDivElement>
                                            ) => {
                                              e.stopPropagation();
                                              handleSelectSeniorityLevel(level);
                                            }}
                                          >
                                            {level}
                                          </AddEmployeeStyledOption>
                                        );
                                      }
                                    )}
                                  </AddEmployeeStyledOptionsWrapper>
                                )}
                                <DownArrowContainer
                                  right="20px"
                                  top="14px"
                                  isOpen={isSeniorityLevelDropdownOpen}
                                  height="16px"
                                  width="16px"
                                >
                                  <DownArrow />
                                </DownArrowContainer>
                              </AddEmployeeStyledSelectWrapper>
                            </AddEmployeeInputAndErrorContainer>
                          </WrapperDiv>
                          <WrapperDiv>
                            <AddEmployeeInputAndErrorContainer
                              width="50%"
                              ref={inputRefs.date_of_employment}
                            >
                              <Text
                                mt={0}
                                mb={8}
                                fontSize={18}
                                fontWeight={700}
                                fontFamily={font.family}
                              >
                                Date of employment
                              </Text>
                              <SelectDate
                                onChange={(value) =>
                                  handleDateChange(value, 'date_of_employment')
                                }
                                onClean={() =>
                                  handleDateClear('date_of_employment')
                                }
                                style={{
                                  width: '100%',
                                  borderRadius: '8px',
                                  borderStyle: 'none',
                                  height: '48px',
                                  padding: '0 12px',
                                  backgroundColor: colors.neutral_50,
                                }}
                                defaultValue={
                                  new Date(newEmployee.date_of_employment)
                                }
                              />
                              {invalidFieldsInputArray.includes(
                                'date_of_employment'
                              ) && (
                                <ErrorLabel>
                                  Please enter a valid date of employment
                                </ErrorLabel>
                              )}
                              {wrongDateOfEmployment && (
                                <ErrorLabel>
                                  Please enter a valid date of employment
                                </ErrorLabel>
                              )}
                            </AddEmployeeInputAndErrorContainer>
                            <AddEmployeeInputAndErrorContainer
                              width="50%"
                              ref={inputRefs.end_of_contract}
                            >
                              <Text
                                mt={0}
                                mb={8}
                                fontSize={18}
                                fontWeight={700}
                                fontFamily={font.family}
                              >
                                End of contract
                              </Text>
                              <SelectDate
                                disabled={newEmployee.indefinite_contract}
                                onChange={(value) =>
                                  handleDateChange(value, 'end_of_contract')
                                }
                                onClean={() =>
                                  handleDateClear('end_of_contract')
                                }
                                style={{
                                  width: '100%',
                                  borderRadius: '8px',
                                  borderStyle: 'none',
                                  height: '48px',
                                  padding: '0 12px',
                                  backgroundColor: colors.neutral_50,
                                }}
                                defaultValue={
                                  new Date(newEmployee.end_of_contract)
                                }
                              />
                              <StyledIndefiniteContractCheckboxWrapper>
                                <StyledIndefiniteContractCheckbox
                                  type="checkbox"
                                  name="indefinite_contract"
                                  checked={newEmployee.indefinite_contract}
                                  onChange={(e) =>
                                    setNewEmployee((prev) =>
                                      prev
                                        ? {
                                            ...prev,
                                            indefinite_contract:
                                              e.target.checked,
                                          }
                                        : prev
                                    )
                                  }
                                />
                                <Text
                                  fontSize={14}
                                  fontWeight={400}
                                  fontFamily={font.family}
                                  color={colors.grey_dark}
                                >
                                  Indefinite contract
                                </Text>
                              </StyledIndefiniteContractCheckboxWrapper>
                              {wrongEndOfContract && (
                                <ErrorLabel>{wrongEndOfContract}</ErrorLabel>
                              )}
                              {invalidFieldsInputArray.includes(
                                'end_of_contract'
                              ) && (
                                <ErrorLabel>
                                  Please enter a valid end of contract date
                                </ErrorLabel>
                              )}
                            </AddEmployeeInputAndErrorContainer>
                          </WrapperDiv>
                        </AddEmployeeSectionContainer>
                      </HiddenInputsWrapper>
                    )}
                    <AddEmployeeSectionContainer>
                      {areAdditionalInputsVisible && (
                        <HiddenInputsWrapper>
                          <Text
                            margin="0"
                            fontSize={33}
                            fontWeight={700}
                            fontFamily={font.family}
                            color={colors.base_black}
                            lineHeight="39.6px"
                          >
                            Experience
                          </Text>
                          <AddEmployeeInputAndErrorContainer>
                            <Text
                              mt={20}
                              mb={8}
                              fontSize={18}
                              fontWeight={700}
                              fontFamily={font.family}
                            >
                              Company
                            </Text>
                            <WrapperDiv justifyContent="space-between">
                              <StyledJobInput
                                width="78%"
                                type="text"
                                name="company_experience"
                                placeholder={`Enter company name`}
                                value={pastCompany}
                                onChange={handlePastCompanyChange}
                                onKeyDown={handleAddPastCompanyKeyDown}
                              />
                              <AddEmployeeStyledButton
                                type="button"
                                padding="12px 24px"
                                bgColor={colors.primary_500}
                                color={colors.base_white}
                                borderRadius="8px"
                                onClick={handleAddPastCompany}
                              >
                                Add new
                              </AddEmployeeStyledButton>
                            </WrapperDiv>
                            {pastCompanyList.length !== 0 && (
                              <EducationListWrapper>
                                {pastCompanyList.map((pastCompany, index) => (
                                  <StyledAddedEmail key={index}>
                                    <Text
                                      fontFamily={font.family}
                                      color={colors.base_black}
                                      margin="0"
                                    >
                                      {pastCompany}
                                    </Text>
                                    <DeleteButton
                                      onClick={() =>
                                        handleRemovePastCompany(pastCompany)
                                      }
                                    >
                                      &#10006;
                                    </DeleteButton>
                                  </StyledAddedEmail>
                                ))}
                              </EducationListWrapper>
                            )}
                          </AddEmployeeInputAndErrorContainer>
                          <AddEmployeeInputAndErrorContainer isRelative>
                            <Text
                              mt={20}
                              mb={8}
                              fontSize={18}
                              fontWeight={700}
                              fontFamily={font.family}
                            >
                              Skills
                            </Text>
                            <WrapperDiv gap="16px">
                              <AddEmployeeStyledSelectWrapper
                                onClick={(
                                  e: React.MouseEvent<HTMLDivElement>
                                ) => handleDropdown(e, 'skills')}
                                width="620px"
                                padding="12px 16px 12px 18px"
                              >
                                <Text
                                  fontFamily={font.family}
                                  color={colors.neutral_900}
                                  margin="0"
                                  fontSize={14}
                                  lineHeight="16.8"
                                >
                                  Select skills from the list
                                </Text>
                                {isSkillDropdownOpen && (
                                  <AddEmployeeStyledOptionsWrapper
                                    ref={skillsDropdownRef}
                                  >
                                    {predefinedSkills.map((skill, index) => {
                                      return (
                                        <AddEmployeeStyledOption
                                          key={index}
                                          onClick={(
                                            e: React.MouseEvent<HTMLDivElement>
                                          ) => {
                                            e.stopPropagation();
                                            handleSkillSelect(skill);
                                          }}
                                        >
                                          {skill}
                                        </AddEmployeeStyledOption>
                                      );
                                    })}
                                  </AddEmployeeStyledOptionsWrapper>
                                )}
                                <DownArrowContainer
                                  right="20px"
                                  top="14px"
                                  isOpen={isSkillDropdownOpen}
                                  height="16px"
                                  width="16px"
                                >
                                  <DownArrow />
                                </DownArrowContainer>
                              </AddEmployeeStyledSelectWrapper>
                              <StyledJobInput
                                width="60%"
                                type="text"
                                placeholder={`Type custom skill..`}
                                value={skill}
                                onChange={handleCustomSkillChange}
                                onKeyDown={handleCustomSkillAddKeyDown}
                              />
                            </WrapperDiv>
                            {skillList.length !== 0 && (
                              <EducationListWrapper>
                                {skillList.map((skill, index) => (
                                  <StyledAddedEmail key={index}>
                                    <Text
                                      fontFamily={font.family}
                                      color={colors.base_black}
                                      margin="0"
                                    >
                                      {skill}
                                    </Text>
                                    <DeleteButton
                                      onClick={() => handleRemoveSkill(skill)}
                                    >
                                      &#10006;
                                    </DeleteButton>
                                  </StyledAddedEmail>
                                ))}
                              </EducationListWrapper>
                            )}
                          </AddEmployeeInputAndErrorContainer>
                        </HiddenInputsWrapper>
                      )}
                      <WrapperDiv gap="16px">
                        <AddEmployeeInputAndErrorContainer
                          width="33.33%"
                          ref={inputRefs.cv}
                        >
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            CV
                          </Text>
                          {/* <CustomUploadLabel htmlFor="cv">
                            {newEmployee.cv && `${CVName}`}
                            {!newEmployee.cv && `Attach File (pdf, png, jpg)`}
                          </CustomUploadLabel> */}
                          <CustomUploadLabel htmlFor="cv">
                            Attach File (pdf only)
                          </CustomUploadLabel>
                          <HiddenUploadInput
                            type="file"
                            name="cv"
                            id="cv"
                            accept="application/pdf, image/*"
                            onChange={handleFileUpload}
                          />
                          <WrapperDiv mt="8px">
                            {cvFile && (
                              <StyledAddedEmail>
                                <Text
                                  fontFamily={font.family}
                                  color={colors.base_black}
                                  margin="0"
                                >
                                  {cvFile.name}
                                </Text>
                                <DeleteButton onClick={handleRemoveCV}>
                                  &#10006;
                                </DeleteButton>
                              </StyledAddedEmail>
                            )}
                          </WrapperDiv>
                          {isCVTooBig && (
                            <ErrorLabel>
                              The file size exceeds the 25MB limit. Please
                              upload a CV that is less than 25MB.
                            </ErrorLabel>
                          )}
                        </AddEmployeeInputAndErrorContainer>
                        <AddEmployeeInputAndErrorContainer width="33.33%">
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Portfolio
                          </Text>
                          <CustomUploadLabel htmlFor="portfolio">
                            Attach File (pdf, png, jpg)
                          </CustomUploadLabel>
                          <HiddenUploadInput
                            type="file"
                            name="portfolio"
                            id="portfolio"
                            accept="application/pdf, image/*"
                            onChange={handleFileUpload}
                          />
                          <WrapperDiv mt="8px">
                            {portfolioFile && (
                              <StyledAddedEmail>
                                <Text
                                  fontFamily={font.family}
                                  color={colors.base_black}
                                  margin="0"
                                >
                                  {portfolioFile.name}
                                </Text>
                                <DeleteButton onClick={handleRemovePortfolio}>
                                  &#10006;
                                </DeleteButton>
                              </StyledAddedEmail>
                            )}
                          </WrapperDiv>
                        </AddEmployeeInputAndErrorContainer>
                        <AddEmployeeInputAndErrorContainer width="33.33%">
                          <Text
                            mt={0}
                            mb={8}
                            fontSize={18}
                            fontWeight={700}
                            fontFamily={font.family}
                          >
                            Documents
                          </Text>
                          <CustomUploadLabel htmlFor="documents">
                            Attach File (pdf, png, jpg)
                          </CustomUploadLabel>
                          <HiddenUploadInput
                            type="file"
                            name="documents"
                            id="documents"
                            accept="application/pdf, image/*"
                            onChange={handleFileUpload}
                            multiple
                          />
                          <WrapperDiv flexDirection="column" gap="8px" mt="8px">
                            {multipleFiles.map((file, index) => (
                              <StyledAddedEmail key={index}>
                                <EllipsisText
                                  fontFamily={font.family}
                                  color={colors.base_black}
                                  margin="0"
                                >
                                  {file.name}
                                </EllipsisText>
                                <DeleteButton
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  &#10006;
                                </DeleteButton>
                              </StyledAddedEmail>
                            ))}
                          </WrapperDiv>
                        </AddEmployeeInputAndErrorContainer>
                      </WrapperDiv>
                    </AddEmployeeSectionContainer>
                    <ViewAllInputsBtn
                      type="button"
                      onClick={() =>
                        setAreAdditionalInputsVisible(
                          !areAdditionalInputsVisible
                        )
                      }
                    >
                      {areAdditionalInputsVisible
                        ? 'Hide additional'
                        : 'View all'}{' '}
                      {areAdditionalInputsVisible ? (
                        <UpArrow color={colors.neutral_700} />
                      ) : (
                        <DownArrow color={colors.neutral_700} />
                      )}
                    </ViewAllInputsBtn>
                    <WrapperDiv justifyContent="end" gap="8px">
                      <AddEmployeeStyledButton
                        bgColor={colors.primary_400}
                        borderRadius="4px"
                        padding="8px 40px"
                        color={colors.base_white}
                        type="button"
                        onClick={() => navigate(`${company.name}/employees`)}
                      >
                        Cancel
                      </AddEmployeeStyledButton>
                      <AddEmployeeStyledButton
                        bgColor={colors.primary_600}
                        borderRadius="4px"
                        padding="8px 40px"
                        color={colors.base_white}
                        type="submit"
                      >
                        Add
                      </AddEmployeeStyledButton>
                    </WrapperDiv>
                  </AddEmployeeFormContainer>
                </form>
              )}
            </ContentAndButtonsContainer>
          </NavbarAndContentContainer>
        </ContentWrapper>
        {togglePopup && (
          <ConfirmPopup
            msgText={popupMsg}
            handleToggler={handleToggle}
            navigate={navigate}
            navigateTo={`/${company.name}/employees`}
          />
        )}
        {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
      </MainContentWrapper>
    </LayoutContainer>
  );
};

import React, { useEffect, useRef, useState } from 'react';
import { NotificationBellIcon } from '../../icons/bell';
import { Text, font } from '../../../style-utils/text';
import { useNotificationsHook } from '../../hooks/use-notifications/use-notifications';
import { Notification } from '../../hooks/use-notifications/interfaces';

import {
  NotificationPanel,
  IconAndTextWrapper,
  NotificationItem,
  UnseenCount,
  ApproveButton,
  DenyButton,
  StatusMessage,
} from './notification-component.styled';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { Spinner } from '../../icons/spinner';
import styled, { keyframes } from 'styled-components';
import { NOTIFICATION_TYPES } from '../../pages/employees/notification-preferences/notification-types';

interface User {
  // eslint-disable-next-line
  user: any;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadingMessage = styled(StatusMessage)`
  animation: ${fadeIn} 2s ease-in-out;
`;

export const NotificationComponent: React.FC<User> = ({ user }) => {
  const preferences = {
    receive_in_app_notifications: user.receive_in_app_notifications,
    receive_edit_notifications: user.receive_edit_notifications,
    receive_time_off_notifications: user.receive_time_off_notifications,
  };

  const {
    userNotifications,
    isNotificationPanelOpen,
    toggleNotificationPanel,
    handleNotificationClick,
    unseenCount,
    setNotificationPanelOpen,
  } = useNotificationsHook(user.id, preferences);

  const panelRef = useRef<HTMLDivElement>(null);
  const [statusMessages, setStatusMessages] = useState<{
    [key: string]: string;
  }>({});
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setNotificationPanelOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationPanelOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationPanelOpen]);

  const handleApprove = async (notification: Notification) => {
    setLoading((prev) => ({ ...prev, [notification.id]: true }));
    try {
      await axios.put(
        `${apiRoute}/timeoff-application/${notification.id}/approve`
      );
      setStatusMessages((prev) => ({ ...prev, [notification.id]: 'Success!' }));
    } catch (error) {
      setStatusMessages((prev) => ({ ...prev, [notification.id]: 'Failed!' }));
    } finally {
      setLoading((prev) => ({ ...prev, [notification.id]: false }));
      setTimeout(() => {
        setStatusMessages((prev) => {
          const updatedMessages = { ...prev };
          delete updatedMessages[notification.id];

          return updatedMessages;
        });
      }, 2000);
    }
  };

  const handleDeny = async (notification: Notification) => {
    const reason = prompt('Please provide a reason for denial:');
    console.log('deny here:');
    console.log(`${apiRoute}/timeoff-application/${notification.id}/deny`);
    if (reason) {
      setLoading((prev) => ({ ...prev, [notification.id]: true }));
      try {
        await axios.put(
          `${apiRoute}/timeoff-application/${notification.id}/deny`,
          { reason }
        );
        setStatusMessages((prev) => ({
          ...prev,
          [notification.id]: 'Success!',
        }));
      } catch (error) {
        setStatusMessages((prev) => ({
          ...prev,
          [notification.id]: 'Failed!',
        }));
      } finally {
        setLoading((prev) => ({ ...prev, [notification.id]: false }));
        setTimeout(() => {
          setStatusMessages((prev) => {
            const updatedMessages = { ...prev };
            delete updatedMessages[notification.id];

            return updatedMessages;
          });
        }, 2000);
      }
    }
  };

  return (
    <IconAndTextWrapper onClick={toggleNotificationPanel}>
      <NotificationBellIcon />
      {unseenCount > 0 && <UnseenCount>{unseenCount}</UnseenCount>}
      <Text fontFamily={font.family} mt={6} mb={0}>
        Notification
      </Text>
      {isNotificationPanelOpen && (
        <NotificationPanel ref={panelRef}>
          {userNotifications.map((notification: Notification) => (
            <NotificationItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification.id)}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: notification.title,
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: notification.message,
                }}
              ></p>

              {/* Conditionally render Approve/Deny buttons for HR users only */}
              {notification.type === NOTIFICATION_TYPES.TIME_OFF_REQUEST &&
                user.account_type === 'hr' && ( // Check for 'hr' account type
                  <div>
                    <ApproveButton
                      onClick={() => handleApprove(notification)}
                      disabled={loading[notification.id]}
                    >
                      {loading[notification.id] ? <Spinner /> : 'Approve'}
                    </ApproveButton>
                    <DenyButton
                      onClick={() => handleDeny(notification)}
                      disabled={loading[notification.id]}
                    >
                      {loading[notification.id] ? <Spinner /> : 'Deny'}
                    </DenyButton>
                  </div>
                )}

              {statusMessages[notification.id] && (
                <FadingMessage
                  isSuccess={statusMessages[notification.id] === 'Success!'}
                >
                  {statusMessages[notification.id]}
                </FadingMessage>
              )}
            </NotificationItem>
          ))}
        </NotificationPanel>
      )}
    </IconAndTextWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useAuth } from '../../context/auth-provider';
import { LogIn } from '../login/login';
import { SignUpStep2 } from '../login/sign-up-step-2';
import { MyCompany } from '../pages/home/home';
import { JobOffers } from '../pages/jobs/job-offers';
import { Candidates } from '../pages/applicants/candidates/candidates';
import { TimeAndAttendanceDashboard } from '../pages/time-and-attendance/dashboard';
import { TeamManagement } from '../pages/time-and-attendance/team-management';
import { EmailVerification } from '../pages/email-verification/email-verification';
import { EditJobOffer } from '../pages/jobs/edit-job-offer';
import { RegisterUser } from '../register/register-user';
import { RegisterCompany } from '../register/register-company';
import { Employees } from '../pages/employees/employees';
import { CandidateProfile } from '../pages/applicants/candidate-profile/candidate-profile';
import { SelectedJobProvider } from '../../context/selected-job-context';
import { AddAdmin } from '../pages/add-admin/add-admin';
import { CompanyJobBoard } from '../pages/company-job-board/company-job-board';
import { InterviewConfirmation } from '../pages/interview-confirmation/interview-confirmation';
import { JobOfferConfirmation } from '../pages/interview-confirmation/job-offer-confirmation';
import { Employee } from '../pages/employees/employee';
import { EditEmployee } from '../pages/employees/edit-employee';
import { InterviewsToday } from '../pages/interviews/interviews-today/interviews-today';
import { HotCandidates } from '../pages/applicants/hot-candidates/hot-candidates';
import { CreateJobOfferFlow } from '../pages/jobs/create-job-offer/create-job-offer-flow';
import { TestPage } from '../pages/test-stripe/test-stripe';
import { TestPaypal } from '../pages/test-paypal/test-paypal';
import { SharedApplyForm } from '../pages/job-board/shared-apply-form';
import SettingsMain from '../pages/settings/settings-main';
import {
  AddEmployee,
  NewEmployeeDataTypes,
} from '../pages/employees/add-employee';
import EmployeesTimeoff from '../pages/employees/employees-timeoff-table/employees-timeoff';
import { SuperAdminHome } from '../pages/super-admin/home';
import { SuperAdminSettings } from '../pages/super-admin/settings';
import { SuperAdminTab } from '../pages/super-admin/super-admin-main/super-admin';
import AdminCompanyInfo from '../pages/super-admin/company-info/admin-company-info';
import { CompaniesMain } from '../pages/super-admin/companies/companies-main';
import NotificationTesting from '../../notification-testing';
import { RejectedCandidates } from '../pages/applicants/candidates/rejected-candidates/rejected-candidates';
import { LandingPage } from '../../landing-page/landing-page';
import { JobPostMobile } from '../pages/company-job-board/job-post-mobile';
import { ForgotPassword } from '../register/forgot-password';
import { SetNewPassword } from '../register/set-new-password';
import { PrivacyPolicy } from '../../landing-page/pages/privacy-policy/privacy-policy';
import { TermsOfUse } from '../../landing-page/pages/terms-of-use/terms-of-use';
import { SubscriptionPlansPage } from '../pages/super-admin/subscription-plans/subscription-plans-main';
import { Footer } from '../footer/footer';
import CompanyCV from '../pages/company-cv/company-cv';
import useCheckTrialPlan from '../hooks/use-check-trial';
import { ChangePlanPage } from '../pages/change-plan';
import { EmployeesSkillsTable } from '../pages/employees/employees-skills-table/employees-skills-table';
import { ProtectedRoute } from './protected-route-hoc';
import { SurveyDashboard } from '../pages/survey/survey-dashboard/survey-dashboard';
import { CreateSurveyPage } from '../pages/survey/create-survey/create-survey';
import { PaymentFail } from '../pages/payment/fail';
import { PaymentSuccess } from '../pages/payment/success';
import { BroadcastMain } from '../pages/super-admin/broadcast/broadcast-main';
import { SurveyDetailsMainPage } from '../pages/survey/survey-details/survey-details-main';
import { EmployeeSurvey } from '../pages/survey/employee-survey/survey-modal';
import Reporting from '../pages/reporting/reporting';
import { useCheckAuth } from '../hooks/use-check-auth';

export const AppRouter = () => {
  const { user, cookies, setUser, refetchUser, loading } = useAuth();
  const [editableJobOfferData, setEditableJobOfferData] = useState();
  const [editableEmployeeData, setEditableEmployeeData] =
    useState<NewEmployeeDataTypes>();

  const navigate = useNavigate();
  const userId = user ? user.id : null;

  useCheckTrialPlan(userId);
  useCheckAuth(user, refetchUser, loading);

  // eslint-disable-next-line
  const updateEditableJobData = (jobToBeUpdated: any) => {
    setEditableJobOfferData(jobToBeUpdated);
  };

  const updateEditableEmployeeData = (
    employeeToBeUpdated: NewEmployeeDataTypes
  ) => {
    setEditableEmployeeData(employeeToBeUpdated);
  };
  const location = useLocation();
  const [hasNavigatedAfterLogin, setHasNavigatedAfterLogin] = useState(false);

  useEffect(() => {
    if (user && user.entityType) {
      const companyName =
        user.entityType === 'employee' ? user.company?.name : user.name;

      const normalizedCompanyName = companyName
        ? companyName.replace(/\s+/g, '-')
        : null;

      const isCompanySpecificRoute =
        normalizedCompanyName &&
        decodeURIComponent(location.pathname) === `/${normalizedCompanyName}`;

      const isRootPath = location.pathname === '/';
      const isChangePlanRoute = location.pathname === '/change-plan';
      const isApplyRoute = location.pathname.startsWith('/apply');

      if (!hasNavigatedAfterLogin) {
        const targetPath =
          user.entityType === 'employee' ? '/employee' : '/admin';

        const shouldRedirect =
          !isCompanySpecificRoute &&
          !isRootPath &&
          !isChangePlanRoute &&
          !isApplyRoute;

        if (shouldRedirect && location.pathname !== targetPath) {
          console.log('Navigating to:', targetPath);
          navigate(targetPath);
          setHasNavigatedAfterLogin(true);
        }
      }
    } else {
      if (hasNavigatedAfterLogin) {
        setHasNavigatedAfterLogin(false);
      }
    }
  }, [user, hasNavigatedAfterLogin, navigate, location.pathname]);

  return (
    <>
      <Routes>
        <Route
          path="/payment-success"
          element={<PaymentSuccess user={user} setUser={setUser} />}
        />
        <Route path="/payment-fail" element={<PaymentFail />} />
        <Route
          path="/change-plan"
          element={
            <ChangePlanPage
              user={user}
              setUser={setUser}
              refetchUser={refetchUser}
            />
          }
        />
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route
          path="/forgot-password"
          element={<ForgotPassword navigate={navigate} />}
        />
        <Route
          path="/set-new-password"
          element={<SetNewPassword navigate={navigate} />}
        />
        <Route
          path="/apply/:companyId/:jobId"
          element={<SharedApplyForm setUser={setUser} user={user} />}
        />
        <Route path="/test-notifications" element={<NotificationTesting />} />
        <Route path="/test-paypal" element={<TestPaypal />} />
        <Route path="/test-payment" element={<TestPage />} />
        <Route
          path="/job-board/mobile-job-post/:companyId/:jobId"
          element={<JobPostMobile setUser={setUser} company={user} />}
        />
        <Route
          path="/global"
          element={
            <CompanyJobBoard
              setUser={setUser}
              company={user}
              fromPage="global"
            />
          }
        />
        <Route
          path="/:companyName"
          element={<CompanyJobBoard setUser={setUser} company={user} />}
        />
        <Route
          path="/login"
          element={
            <LogIn setUser={setUser} navigate={navigate} cookies={cookies} />
          }
        />
        <Route
          path="/register-company"
          element={
            <RegisterCompany
              user={user}
              navigate={navigate}
              cookies={cookies}
            />
          }
        />
        <Route
          path="/register-user"
          element={
            <RegisterUser user={user} navigate={navigate} cookies={cookies} />
          }
        />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/verify-employee-email" element={<EmailVerification />} />
        <Route path="/step-2" element={<SignUpStep2 />} />
        <Route path="/confirm-interview" element={<InterviewConfirmation />} />
        <Route path="/confirm-job-offer" element={<JobOfferConfirmation />} />
        {/* Company routes */}
        <Route
          path={`/admin`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <MyCompany setUser={setUser} user={user} navigate={navigate} />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/calendar`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <MyCompany setUser={setUser} user={user} navigate={navigate} />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/applicants/:applicantId`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <CandidateProfile
                user={user}
                setUser={setUser}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/jobs/job-offers`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <JobOffers
                  setUser={setUser}
                  company={user}
                  cookies={cookies}
                  navigate={navigate}
                  updateEditableJobData={updateEditableJobData}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/applicants`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <Candidates
                  setUser={setUser}
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/rejected`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <RejectedCandidates
                  setUser={setUser}
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/jobs/create-job-offer`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <CreateJobOfferFlow
                setUser={setUser}
                user={user}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/jobs/edit-job-offer`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <EditJobOffer
                setUser={setUser}
                company={user}
                cookies={cookies}
                editableJobOfferData={editableJobOfferData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/time-and-attendance/dashboard`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <TimeAndAttendanceDashboard
                setUser={setUser}
                user={user}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/time-and-attendance/team-management`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <TeamManagement
                user={user}
                navigate={navigate}
                setUser={setUser}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/benefits/benefits`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SignUpStep2 />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/employees`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <Employees
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:companyName/employees/:id"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <Employee
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
                updateEmployeeData={updateEditableEmployeeData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:companyName/employees/add-employee"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <AddEmployee
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:companyName/employees/:id/edit"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <EditEmployee
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
                employee={editableEmployeeData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/interviews-today`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <InterviewsToday
                  setUser={setUser}
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/hot-prospects`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <HotCandidates
                  setUser={setUser}
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/survey`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SurveyDashboard
                setUser={setUser}
                user={user}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/survey/:id`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SurveyDetailsMainPage
                setUser={setUser}
                user={user}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/survey/create`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <CreateSurveyPage
                setUser={setUser}
                user={user}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />

        {/* This is example route for the employee survey modal, before the back-end is done */}
        <Route path={`/employee-survey`} element={<EmployeeSurvey />} />

        <Route
          path={`/:companyName/settings`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <SettingsMain
                  refetchUser={refetchUser}
                  setUser={setUser}
                  user={user}
                  cookies={cookies}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/employees/dayoff`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <EmployeesTimeoff
                  user={user}
                  setUser={setUser}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/:companyName/employees/skill-map"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <EmployeesSkillsTable
                  setUser={setUser}
                  user={user}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/:employeeApplicantId/company-cv/:action/:companyCvId?`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <SelectedJobProvider>
                <CompanyCV
                  setUser={setUser}
                  company={user}
                  navigate={navigate}
                />
              </SelectedJobProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/reporting`}
          element={
            <ProtectedRoute
              entityType={user?.entityType}
              requiredEntityType={'company'}
              user={user}
              loading={loading}
            >
              <Reporting company={user} navigate={navigate} setUser={setUser} />
            </ProtectedRoute>
          }
        />
        {/* Admin routes */}
        <Route
          path={`/:companyName/admins`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <AddAdmin
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={`/:companyName/admins/add`}
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'company'}
            >
              <AddAdmin
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        {/* Company routes END*/}
        {/* Employee routes END*/}
        <Route
          path="/employee"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.entityType}
              requiredEntityType={'employee'}
            >
              <Employee
                setUser={setUser}
                company={user}
                cookies={cookies}
                navigate={navigate}
                updateEmployeeData={updateEditableEmployeeData}
              />
            </ProtectedRoute>
          }
        />
        {/* Employee routes END*/}
        {/* Super Admin routes */}
        <Route
          path="/super-admin"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <SuperAdminHome
                setUser={setUser}
                user={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id/super-admin/settings"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <SuperAdminSettings
                setUser={setUser}
                user={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id/super-admin/broadcast"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <BroadcastMain
                setUser={setUser}
                user={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id/super-admin/admins"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <SuperAdminTab
                setUser={setUser}
                user={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id/super-admin/super-admin"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <AdminCompanyInfo
                handleBack={() => console.log('Not Implemented')}
                setUser={setUser}
                user={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id/super-admin/subscription-plans"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <SubscriptionPlansPage
                setUser={setUser}
                user={user}
                cookies={cookies}
                navigate={navigate}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:id/super-admin/companies"
          element={
            <ProtectedRoute
              user={user}
              loading={loading}
              entityType={user?.account_type}
              requiredEntityType={'superAdmin'}
            >
              <CompaniesMain
                setUser={setUser}
                navigate={navigate}
                user={user}
              />
            </ProtectedRoute>
          }
        />
        {/* Super Admin routes END*/}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
};

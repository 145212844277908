import React, { useEffect, useRef, useState } from 'react';
import {
  LogInWrapper,
  LogInFormContainer,
  LogInForm,
  LogInTitle,
  LogInSubTitle,
  TextContainer,
  CompanyTitle,
  Description,
  InputContainer,
  Label,
  StyledInput,
  ButtonTitle,
  BlueButton,
  LogInFormWrapper,
  LoginSignupWrapper,
  LoginOrSignupButton,
  ErrorMessage,
  ButtonsContainer,
  CompanySubtitle,
} from '../login/login.styled';
import { useAuthForms } from '../hooks/use-auth-forms';
import logoTeam from '../../logo_team.png';
import { ErrorLabel } from '../pages/jobs/jobs.styled';
import { NavigateFunction } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../constants/api-constants';
import toast from 'react-hot-toast';
import { Spinner } from '../icons/spinner';
import { colors } from '../../style-utils/colors';

interface ForgotPasswordProps {
  navigate: NavigateFunction;
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ navigate }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { logInData, handleInputChange } = useAuthForms();
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (!logInData.email) {
      setIsEmailEmpty(true);

      return;
    }

    setIsLoading(true);
    setInvalidCredentials(false);
    setSuccessMessage(null);

    try {
      await axios.post(
        `${apiRoute}/forgot-password`,
        {
          email: logInData.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setSuccessMessage(
        'Check your email if you have a Worwise account associated with it.'
      );
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;

      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 404:
            setInvalidCredentials(true);
            toast.error('Email not found');
            break;
          case 400:
            setInvalidCredentials(true);
            toast.error('Invalid request');
            break;
          default:
            toast.error(
              'An unexpected error occurred. Please try again later.'
            );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{ marginTop: '60px', cursor: 'pointer' }}
          src={logoTeam}
          width={100}
        />
        <CompanyTitle>Welcome to WorWise</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome back! Please enter your details to start optimizing your
          team’s hiring process.
        </Description>
      </TextContainer>
      <LogInFormWrapper id="LoginFormWrapper">
        <LogInFormContainer overflow="none">
          <LogInForm onSubmit={onSubmit}>
            {isLoading && <Spinner />}
            <LoginSignupWrapper>
              <LoginOrSignupButton isSelected>
                <LogInTitle isSelected>Request Password Reset</LogInTitle>
              </LoginOrSignupButton>
            </LoginSignupWrapper>
            <LogInSubTitle>
              Enter the email address you use to sign in to Worwise
            </LogInSubTitle>
            <InputContainer>
              <Label>Email address</Label>
              <StyledInput
                ref={emailInputRef}
                bgColor={colors.neutral_50}
                type="text"
                name="email"
                value={logInData.email}
                placeholder={'Enter address'}
                onChange={handleInputChange}
              />
              {isEmailEmpty && (
                <ErrorLabel>Please enter a valid email address</ErrorLabel>
              )}
            </InputContainer>

            {invalidCredentials && (
              <ErrorMessage>Invalid email. Please try again.</ErrorMessage>
            )}
            {successMessage && (
              <ErrorMessage style={{ color: 'green' }}>
                {successMessage}
              </ErrorMessage>
            )}
            <ButtonsContainer>
              <BlueButton type="submit">
                <ButtonTitle fontColor={'white'}>
                  Request Password Reset
                </ButtonTitle>
              </BlueButton>
            </ButtonsContainer>
          </LogInForm>
        </LogInFormContainer>
      </LogInFormWrapper>
    </LogInWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  PricingPackages,
  Content,
  PricingHeading,
  PricingInfo,
  Table,
  TableHeadings,
  TableData,
  TableData1,
  TableBody,
  PricingDownPart,
  PlanButton,
  Paragraph,
} from './pricing.styled';
import checkImg from '../../lp-images/check.png';
import xImg from '../../lp-images/close.png';
import { LandingPageProps } from '../../landing-page';
import { Spinner } from '../../../components/icons/spinner';
import { SubscriptionPlan } from '../../../components/pages/super-admin/subscription-plans/interface';
import { loadSubscriptionPlans } from '../../../function-utils/subscription-plan-utils';

const renderTableIcons = (content: boolean) => {
  return content ? (
    <img src={checkImg} alt="Check" />
  ) : (
    <img src={xImg} alt="X" />
  );
};

export const PricingList: React.FC<LandingPageProps> = React.forwardRef<
  HTMLDivElement,
  LandingPageProps
>((props, ref) => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [features, setFeatures] = useState<string[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const fetchedPlans = await loadSubscriptionPlans();

        const activePlans = fetchedPlans
          .filter((plan) => plan.status === 'active')
          .sort(
            (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
          );

        setPlans(activePlans);

        const allFeatures = activePlans.map((plan) => plan.features).flat();
        const uniqueFeatures = Array.from(new Set(allFeatures));
        setFeatures(uniqueFeatures);
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <PricingPackages id="pricing" ref={ref}>
      <Content>
        <PricingHeading>
          Choose pricing plan which suits your needs
        </PricingHeading>
        <PricingInfo>
          Choose a plan that fits your budget and meets your team’s needs.
        </PricingInfo>
      </Content>

      <Table>
        <thead>
          <tr>
            <TableHeadings>FEATURES</TableHeadings>
            {plans.map((plan) => (
              <TableHeadings key={plan.id}>
                {plan.price.amount}/{plan.price.currency} {plan.title}
              </TableHeadings>
            ))}
          </tr>
        </thead>
        <TableBody>
          {features.map((feature, i) => (
            <tr key={i}>
              <TableData1>{feature}</TableData1>
              {plans.map((plan) => (
                <TableData key={plan.id}>
                  {renderTableIcons(plan.features.includes(feature))}
                </TableData>
              ))}
            </tr>
          ))}
        </TableBody>
      </Table>

      <PricingDownPart>
        <Link to="/register-company" target="blank">
          <PlanButton>Choose Your Plan</PlanButton>
        </Link>
        <Paragraph>
          Or Contact us for a custom plan. No hidden charges!
        </Paragraph>
      </PricingDownPart>
    </PricingPackages>
  );
});

PricingList.displayName = 'PricingList';

import styled from 'styled-components';

export const PlanWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  background: red;
`;

export const StyledNextButton = styled.div`
  display: flex;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--rounded-1, 4px);
  background: var(--Secondary-500, #2e77b8);
  color: var(--Shadow-0, #fdfdfd);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: flex-end;
  cursor: pointer;
`;

export const StyledCancelButton = styled.div`
  display: flex;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--rounded-1, 4px);
  background: red;
  color: var(--Shadow-0, #fdfdfd);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: flex-end;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

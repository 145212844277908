import React, { useEffect, useState } from 'react';
import { Company } from '../../app-router/component-props';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../layout/layout-container.styled';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Sidebar } from '../../layout/sidebar/sidebar';
import { NavigateFunction, useParams } from 'react-router-dom';
import { Chat } from '../../chat/chat';
import {
  StyledCenterCVComponent,
  StyledCompanyCVBackAndSaveButton,
  StyledCompanyCVBackAndSaveButtons,
  StyledCompanyCVBackAndSaveButtonsWrapper,
  StyledCompanyCVMainWrapper,
  StyledExportEditBackBtn,
  StyledLoadingWrapper,
} from './company-cv.styled';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { LeftArrowSettings } from '../../icons/arrow-left-settings';
import CompanyCvComponent from './components/company-cv-component/company-cv-component';
import { useEmployeeCVData } from './hooks/use-employee-company-cv-data';
import { Spinner } from '../../icons/spinner';
import CompanyCvEditComponent from './components/edit-company-cv-component/company-cv-edit-component';
import MessagePopup from '../../messages/message-popup/message-popup';

interface CompanyCVProps {
  company: Company;
  navigate: NavigateFunction;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

const CompanyCV: React.FC<CompanyCVProps> = ({
  company,
  navigate,
  setUser,
}) => {
  const height = useWindowHeight();
  const { action, companyCvId, employeeApplicantId } = useParams();

  const [edit, setEdit] = useState<boolean>(false);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);

  const {
    employeeCVData,
    loading,
    updateField,
    updateWorkExperience,
    addNewWorkExperience,
    addWorkExperienceResponsibility,
    editWorkExperienceResponsibility,
    deleteWorkExperience,
    deleteResponsibility,
    addSkill,
    deleteSkill,
    addTool,
    deleteTool,
    addEducation,
    updateEducation,
    deleteEducation,
    addEducationDetail,
    updateEducationDetail,
    deleteEducationDetail,
    newProfilePicture,
    setNewProfilePicture,
    deleteProfilePicture,
    saveCVData,
    fetchCVData,
    fetchCVDataById,
  } = useEmployeeCVData();

  useEffect(() => {
    if (action === 'edit' && companyCvId) {
      fetchCVDataById(companyCvId);
    } else if (action === 'create' && companyCvId && employeeApplicantId) {
      const decodedCvUrl = decodeURIComponent(companyCvId);
      fetchCVData(decodedCvUrl, employeeApplicantId, false);
    } else if (action === 'new' && employeeApplicantId) {
      setEdit(true);
      updateField('candidate_id', employeeApplicantId);
    }
  }, [employeeApplicantId, action, companyCvId]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
        navigate(-1);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  if (loading) {
    return (
      <StyledLoadingWrapper>
        <Spinner />
      </StyledLoadingWrapper>
    );
  }

  return (
    <LayoutContainer height={height}>
      <TopMenu navigationUrl={'/admin'} user={company} setUser={setUser} />
      <MainContentWrapper>
        <Sidebar
          setUser={setUser}
          company={company}
          selectedOption={''}
          navigate={navigate}
        />
        <ContentWrapper>
          <NavbarAndContentTransparentContainer>
            <StyledCompanyCVMainWrapper>
              <StyledExportEditBackBtn onClick={() => navigate(-1)}>
                <LeftArrowSettings />
                <Text
                  fontFamily={font.family}
                  fontSize={16}
                  fontWeight={400}
                  color={colors.neutral_400}
                  margin="0"
                >
                  Back
                </Text>
              </StyledExportEditBackBtn>
              <StyledCenterCVComponent>
                {!edit && (
                  <CompanyCvComponent
                    company={company}
                    employeeCVData={employeeCVData}
                    setEdit={setEdit}
                    newProfilePicture={newProfilePicture}
                  />
                )}
                {edit && (
                  <CompanyCvEditComponent
                    company={company}
                    employeeCVData={employeeCVData}
                    setEdit={setEdit}
                    updateField={updateField}
                    updateWorkExperience={updateWorkExperience}
                    addNewWorkExperience={addNewWorkExperience}
                    addWorkExperienceResponsibility={
                      addWorkExperienceResponsibility
                    }
                    editWorkExperienceResponsibility={
                      editWorkExperienceResponsibility
                    }
                    deleteWorkExperience={deleteWorkExperience}
                    deleteResponsibility={deleteResponsibility}
                    addSkill={addSkill}
                    deleteSkill={deleteSkill}
                    addTool={addTool}
                    deleteTool={deleteTool}
                    addEducation={addEducation}
                    updateEducation={updateEducation}
                    deleteEducation={deleteEducation}
                    addEducationDetail={addEducationDetail}
                    updateEducationDetail={updateEducationDetail}
                    deleteEducationDetail={deleteEducationDetail}
                    newProfilePicture={newProfilePicture}
                    setNewProfilePicture={setNewProfilePicture}
                    deleteProfilePicture={deleteProfilePicture}
                  />
                )}
                <StyledCompanyCVBackAndSaveButtonsWrapper>
                  <StyledCompanyCVBackAndSaveButtons>
                    <StyledCompanyCVBackAndSaveButton
                      backgroundColor={colors.secondary_500}
                      onClick={async () => {
                        const success = await saveCVData();
                        if (success) {
                          setPopUp({
                            text: 'CV saved successfully!',
                            type: 'success',
                          });
                        } else {
                          setPopUp({
                            text: 'Failed to save CV.',
                            type: 'error',
                          });
                        }
                      }}
                      disabled={loading} // Optional: Disable button while loading
                    >
                      Save Draft
                    </StyledCompanyCVBackAndSaveButton>
                    <StyledCompanyCVBackAndSaveButton
                      backgroundColor={colors.primary_500}
                      onClick={() => navigate(-1)}
                    >
                      Back to Dashboard
                    </StyledCompanyCVBackAndSaveButton>
                  </StyledCompanyCVBackAndSaveButtons>
                </StyledCompanyCVBackAndSaveButtonsWrapper>
              </StyledCenterCVComponent>
            </StyledCompanyCVMainWrapper>
          </NavbarAndContentTransparentContainer>
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </LayoutContainer>
  );
};

export default CompanyCV;

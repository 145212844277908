import axios from 'axios';
import Cookies from 'universal-cookie';
import { apiRoute } from '../constants/api-constants';
// Removed import of NavigateFunction since we're not using navigate anymore
import { flushSync } from 'react-dom';
import { NavigateFunction } from 'react-router-dom';

const cookies = new Cookies();
const testing = true;

export const logOut = async (
  // eslint-disable-next-line
  setUser: (user: any | null | undefined) => void,
  navigate?: NavigateFunction
) => {
  if (navigate) if (testing) console.log('Starting logout process...');

  try {
    if (testing)
      console.log('Sending logout request to API:', `${apiRoute}/logOut`);

    await axios.post(`${apiRoute}/logOut`);
    if (testing) console.log('Logout API call completed successfully');

    if (testing) console.log('Cookies before removal:', document.cookie);

    document.cookie.split(';').forEach((cookie) => {
      const cookieName = cookie.trim().split('=')[0];
      if (cookieName.startsWith('authorization_token')) {
        if (testing) console.log(`Removing cookie: ${cookieName}`);
        cookies.remove(cookieName, { path: '/' }); // Add domain if necessary
      } else {
        if (testing) console.log(`Skipping cookie: ${cookieName}`);
      }
    });

    if (testing) console.log('Cookies after removal attempt:', document.cookie);

    if (testing)
      console.log(
        'Local storage before removal:',
        localStorage.getItem('authorization_token')
      );
    if (testing)
      console.log(
        'Session storage before removal:',
        sessionStorage.getItem('authorization_token')
      );

    localStorage.removeItem('authorization_token');
    sessionStorage.removeItem('authorization_token');

    if (testing)
      console.log(
        'Authorization tokens removed from local and session storage'
      );

    if (testing) console.log('Setting user to null');
    flushSync(() => {
      setUser(null);
    });

    if (testing) console.log('Redirecting to login page');

    // Always use a hard redirect
    if (testing) console.log('Navigating using window.location.href to /login');
    window.location.href = '/login';
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

import React, { useRef, useState } from 'react';
import {
  LogInWrapper,
  LogInFormContainer2,
  LogInForm,
  LogInTitle,
  TextContainer,
  CompanyTitle,
  Description,
  SignUpFormWrapper,
  LoginSignupWrapper,
  LoginOrSignupButton,
  CompanySubtitle,
} from '../login/login.styled';
import { Spinner } from '../icons/spinner';
import { DefaultComponentProps } from '../app-router/component-props';
import { SignUpData, useAuthForms } from '../hooks/use-auth-forms';
import logoTeam from '../../logo_team.png';
import { createItem } from '../requests/requests';
import { RegisterInputsCompany } from './register-main';
import axios from 'axios';

export interface InputRefs {
  name: React.MutableRefObject<HTMLLabelElement | null>;
  website: React.MutableRefObject<HTMLLabelElement | null>;
  industry: React.MutableRefObject<HTMLLabelElement | null>;
  founded: React.MutableRefObject<HTMLLabelElement | null>;
  email: React.MutableRefObject<HTMLLabelElement | null>;
  phoneNumber: React.MutableRefObject<HTMLLabelElement | null>;
  address: React.MutableRefObject<HTMLLabelElement | null>;
  country: React.MutableRefObject<HTMLLabelElement | null>;
  taxNumber: React.MutableRefObject<HTMLLabelElement | null>;
  employeeCount: React.MutableRefObject<HTMLLabelElement | null>;
  description: React.MutableRefObject<HTMLLabelElement | null>;
}

export const RegisterCompany: React.FC<DefaultComponentProps> = ({
  navigate,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    signUpData,
    setSignUpData,
    handleSignUpChange,
    refreshData,
    handleSignUpFoundedDateChange,
  } = useAuthForms();
  const [currentStep, setCurrentStep] = useState(1);
  const [invalidFieldsInputArray, setInvalidFieldsInputArray] = useState<
    string[]
  >([]);
  const totalSteps = 4;

  const inputRefs: InputRefs = {
    name: useRef(null),
    website: useRef(null),
    industry: useRef(null),
    founded: useRef(null),
    email: useRef(null),
    phoneNumber: useRef(null),
    address: useRef(null),
    country: useRef(null),
    taxNumber: useRef(null),
    employeeCount: useRef(null),
    description: useRef(null),
  };
  const registerCompany = async (): Promise<boolean> => {
    setIsLoading(true);
    setErrorMessage(null); // Clear any previous errors
    try {
      const url = `register-company`;
      await createItem(`${url}`, signUpData);

      // If successful, return true
      return true;
    } catch (error) {
      console.error('Registration error:', error);

      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // Display the error message (e.g., "This email address already exists")
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }

      // Return false if there was an error
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  const handleLogoClick = () => {
    if (navigate) navigate('/');
  };

  const validateCompanyForm = (data: SignUpData, step: number) => {
    let requiredFields: (keyof SignUpData)[] = [];

    if (step === 1) {
      requiredFields = ['name', 'website', 'industry', 'founded'];
    } else if (step === 2) {
      requiredFields = [
        'email',
        'phoneNumber',
        'address',
        'country',
        'taxNumber',
      ];
    } else if (step === 3) {
      requiredFields = ['employeeCount', 'description'];
    }

    const emptyFields: string[] = [];

    requiredFields.forEach((field) => {
      const value = data[field];
      if (typeof value !== 'string' || value.length === 0) {
        emptyFields.push(field);
      }
    });

    setInvalidFieldsInputArray(emptyFields);

    return emptyFields;
  };

  const handleNext = () => {
    const invalidFields = validateCompanyForm(signUpData, currentStep);
    if (invalidFields.length === 0) {
      setCurrentStep(currentStep + 1);
    } else {
      const refKey = invalidFields[0];
      inputRefs[refKey as keyof InputRefs].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleRegister = async () => {
    setErrorMessage(null); // Clear any previous errors

    // Call registerCompany and wait for it to complete
    const success = await registerCompany();

    if (success && navigate) {
      refreshData(); // Only refresh data if registration is successful
      navigate('/step-2');
    }
  };

  return (
    <LogInWrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
        />
        <CompanyTitle>WorWise Client Board</CompanyTitle>
        <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
        <Description>
          Welcome! Please enter your details to start optimizing your team’s
          hiring process.
        </Description>
      </TextContainer>
      <SignUpFormWrapper>
        <LogInFormContainer2 overflow="none">
          <LogInForm>
            {isLoading && <Spinner />}
            {currentStep === 1 && (
              <LoginSignupWrapper>
                <LoginOrSignupButton
                  onClick={() => navigate && navigate('/login')}
                  isSelected={false}
                >
                  <LogInTitle isSelected={false}>Log In</LogInTitle>
                </LoginOrSignupButton>
                <LoginOrSignupButton
                  onClick={() => navigate && navigate('/register-company')}
                  isSelected
                >
                  <LogInTitle isSelected={true}>Sign Up</LogInTitle>
                </LoginOrSignupButton>
              </LoginSignupWrapper>
            )}
            <RegisterInputsCompany
              errorMessage={errorMessage}
              isLoading={isLoading}
              navigate={navigate}
              setSignUpData={setSignUpData}
              signUpData={signUpData}
              handleSignUpChange={handleSignUpChange}
              refreshData={refreshData}
              registerCompany={registerCompany}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              handleRegister={handleRegister}
              currentStep={currentStep}
              totalSteps={totalSteps}
              invalidFieldsInputArray={invalidFieldsInputArray}
              inputRefs={inputRefs}
              handleSignUpFoundedDateChange={handleSignUpFoundedDateChange}
            />
          </LogInForm>
        </LogInFormContainer2>
      </SignUpFormWrapper>
    </LogInWrapper>
  );
};

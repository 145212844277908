import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useCheckAuth = (
  // eslint-disable-next-line
  user: any,
  refetchUser: () => Promise<void>,
  loading: boolean
) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyUser = async () => {
      if (!loading) {
        if (!user) {
          try {
            await refetchUser();
            if (!user) {
              navigate('/login');
            }
          } catch (error) {
            console.error('Error verifying user:', error);
            navigate('/login');
          }
        }
      }
    };
    if (user) verifyUser();
  }, [user, loading, refetchUser, navigate, location.pathname]);
};

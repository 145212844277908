import React, { useState, useEffect } from 'react';
import { StyledUserSettingsMainWrapper } from '../user-settings.tsx/user-settings.styled';
import { PaymentPlans } from '../../../../../../../payment-plans/payment-plan';
import { useAuthForms } from '../../../../../../../hooks/use-auth-forms';
import {
  ButtonWrapper,
  StyledNextButton,
  StyledCancelButton,
} from './subscription-plans.styled';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Spinner } from '../../../../../../../icons/spinner';
import { apiRoute } from '../../../../../../../../constants/api-constants';
import { logOut } from '../../../../../../../../function-utils/log-out';
import Cookies from 'universal-cookie';

interface SubscriptionPlansProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null | undefined) => void;
  cookies?: Cookies;
  refetchUser: () => Promise<void>;
}

export const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({
  user,
  setUser,
  refetchUser,
}) => {
  const { signUpData, setSignUpData } = useAuthForms();
  const [planId, setSelectedPlan] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user?.subscription_plan_id) {
      setSelectedPlan(user.subscription_plan_id);
    }
  }, [user?.subscription_plan_id]);

  const handlePlanSelection = (newPlanId: string) => {
    if (newPlanId !== planId) {
      setSelectedPlan(newPlanId);
    }
  };

  const handleNextClick = async () => {
    if (planId) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiRoute}/create-checkout-session`,
          {
            planId,
            customerEmail: user.email,
          }
        );

        const { checkoutSessionId } = response.data;
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
        );

        if (!stripe) {
          console.error('Stripe failed to initialize.');

          return;
        }

        const { error } = await stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        });

        if (error) {
          console.error('Stripe Checkout error:', error.message);
        } else {
          await refetchUser();
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.error(
          'Error creating checkout session:',
          error.message || error
        );
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Please select a plan.');
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${apiRoute}/cancel-subscription`, {
        subscriptionId: user.stripe_subscription_id,
      });

      if (response.status === 200) {
        console.log('Subscription canceled successfully.');

        await refetchUser();
        await logOut(setUser);
      } else {
        console.error(
          'Failed to cancel subscription, status:',
          response.status
        );
      }
      // eslint-disable-next-line
    } catch (error: any) {
      console.error('Error canceling subscription:', error.message || error);
    } finally {
      setLoading(false);
    }
  };

  console.log('plan id from subscription plans:');
  console.log(planId);

  return (
    <StyledUserSettingsMainWrapper>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PaymentPlans
            user={user}
            key={planId}
            isSignUp={false}
            signUpData={signUpData}
            setSignUpData={setSignUpData}
            setSelectedPlan={handlePlanSelection}
            defaultSelectedPlanId={planId}
          />
          <ButtonWrapper>
            <StyledCancelButton onClick={handleCancelSubscription}>
              Cancel Subscription
            </StyledCancelButton>
            <div style={{ width: '30px' }}></div>
            <StyledNextButton onClick={handleNextClick}>Next</StyledNextButton>
          </ButtonWrapper>
        </>
      )}
    </StyledUserSettingsMainWrapper>
  );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import { Spinner } from '../../icons/spinner';
import { fetchAllItems } from '../../requests/requests';
import {
  ContentAndImageContainer,
  ImageContainer,
  CoverImage,
  Overlay,
  CenteredText,
  JobPostingAndInfoContainer,
  JobPostingAndInfoWrapper,
  CareerText,
} from '../company-job-board/company-job-board-table.styled';
import { ApplyForm } from './apply-form';
import { TopMenu } from '../../layout/top-menu/top-menu';
import {
  JobBoardContainer,
  JobBoardWrapper,
} from '../job-board/job-board.styled';
import { colors } from '../../../style-utils/colors';

interface SharedApplyFormProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
}

export const SharedApplyForm: React.FC<SharedApplyFormProps> = ({
  user,
  setUser,
}) => {
  const { jobId, companyId } = useParams<{
    jobId: string;
    companyId: string;
  }>();
  const [job, setJob] = useState<JobOfferWithCompanyData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        setLoading(true);
        const response = await fetchAllItems(`jobs/${jobId}`);
        setJob(response as unknown as JobOfferWithCompanyData);
      } catch (error) {
        console.error('Error fetching job:', error);
      } finally {
        setLoading(false);
      }
    };

    if (jobId) {
      fetchJob();
    }
  }, [jobId]);

  if (loading) {
    return <Spinner />;
  }

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <>
      <JobBoardWrapper>
        <JobBoardContainer
          bgColor={colors.base_white}
          color={colors.base_black}
        >
          <TopMenu
            setUser={setUser}
            user={user}
            bigLogo
            navigationUrl={`/${companyId}/job-board`}
            hideUserAndNotification
          />
        </JobBoardContainer>
        <ContentAndImageContainer>
          <ImageContainer>
            <CoverImage src="/coverImage.jpeg" alt="Cover" />
            <Overlay />
            <CenteredText>
              Your Best way to Exciting&nbsp;<CareerText>Career</CareerText>
              &nbsp;Opportunities
            </CenteredText>
          </ImageContainer>
          <JobPostingAndInfoContainer>
            <JobPostingAndInfoWrapper>
              <ApplyForm job={job} />
            </JobPostingAndInfoWrapper>
          </JobPostingAndInfoContainer>
        </ContentAndImageContainer>
      </JobBoardWrapper>
    </>
  );
};
